import React, {useEffect, useState} from 'react';
import {createSale, getArticles, updateSale} from '../../services/saleService';
import {getAllCustomers} from '../../services/customerService';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import InventoryIcon from '@mui/icons-material/Inventory';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import CloseIcon from "@mui/icons-material/Close";
import ReceiptIcon from '@mui/icons-material/Receipt';

const SaleForm = ({ saleToEdit, onSave, activeTab,viewingOnly, onClose}) => {
  const today = new Date().toISOString().split('.')[0];
  const [creationDate, setCreationDate] = useState(today);
  const [type, setType] = useState('');
  const [amount, setAmount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('Cash');
  const [lines, setLines] = useState([{ article: null, quantity: 1, sellingPrice: 0,  packing: null, showPackingInput: false}]);
  const [articles, setArticles] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState('');
  const [discount, setDiscount] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null); 
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  console.log(type);
  useEffect(() => {
  }, [amount]);

  useEffect(() => {
    const fetchArticlesAndCustomers = async () => {
      try {
        const [fetchedArticles, fetchedCustomersResponse] = await Promise.all([getArticles(), getAllCustomers()]);

        // Store the default selling price for each article
        const articlesWithDefaultPrice = fetchedArticles.map(article => ({
          ...article,
          defaultSellingPrice: article.sellingPrice,
        }));

        setArticles(articlesWithDefaultPrice);
        setCustomers(fetchedCustomersResponse.data);

        if (saleToEdit) {
          const setSaleToEditData = (sale, fetchedArticles, fetchedCustomers) => {
            console.log(sale);
            setCreationDate(sale.creationDate || '');
            setType(capitalizeFirstLetter(sale.type) || '');
            setAmount(Number(sale.amount) || 0);
            setDiscount(sale.discount || 0);
            setPaidAmount(sale.paidAmount || 0);
            setPaymentMethod(capitalizeFirstLetter(sale.paymentMethod) || '');
            setSelectedCustomerId(sale.customer?.id || '');

            const editLines = sale.lineItems.map(item => ({
              ...item,
              article: fetchedArticles.find(article => article.id === item.article.id) || null,
              showPackingInput: item.packing > 1 // Show packing input if packing is greater than 1
            }));
            setLines(editLines.length > 0 ? editLines : [{ article: null, quantity: 1, sellingPrice: 0 }]);
          };
          setSaleToEditData(saleToEdit, fetchedArticles, fetchedCustomersResponse.data);
        }
      } catch (error) {
        console.error('Error fetching articles or customers', error);
      }
    };
    fetchArticlesAndCustomers();
    if (!saleToEdit) {
      // When creating a new sale, default payment method is 'Cash'
      setPaymentMethod('Cash');
    }
  }, [saleToEdit]);


  useEffect(() => {
    if (activeTab !== 'All') {
      setType(convertTabToEnum(activeTab));
    }
  }, [activeTab]);

  useEffect(() => {
    const subtotal = calculateSubtotal();
    const finalAmount = subtotal - discount; // Subtract discount from subtotal
    setAmount(Math.max(finalAmount, 0)); // Prevent negative amounts
  }, [discount, lines]);
  const calculateSubtotal = () => {
    return lines.reduce((acc, line) => {
      // Check if packing is not null and greater than zero
      const effectiveQuantity = (line.packing && line.packing > 0) ? line.quantity * line.packing : line.quantity;
      return acc + (line.sellingPrice * effectiveQuantity);
    }, 0);
  };

  const convertTabToEnum = (tab) => {
    switch (tab) {
      case 'ORDER':
        return 'Order';
      case 'INSTORE':
        return 'Instore';
      case 'DELIVERY':
        return 'Delivery';
      case 'RETURN':
        return 'Return';
      case 'CREDIT':
        return 'Credit';
      default:
        return '';
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const updateLinesAndAmount = (newLines) => {
    const totalAmount = newLines.reduce((acc, line) => acc + (line.sellingPrice * line.quantity), 0);
    setLines(newLines);
    setAmount(totalAmount);
  };

  const handleLineChange = (index, field, value) => {
    const newLines = [...lines];
    const line = newLines[index];

    if (field === 'article') {
      const selectedArticle = articles.find(article => article.id === value);
      line.article = selectedArticle || null;
      line.sellingPrice = selectedArticle ? selectedArticle.defaultSellingPrice : 0;
    } else if (field === 'sellingPrice') {
      line.sellingPrice = value;
    } else if (field === 'packing') {
      line.packing = value === '' ? null : Number(value);
    } else {
      line[field] = value;
    }

    updateLinesAndAmount(newLines);
  };

// Add a onBlur handler to the selling price input field
  const handleSellingPriceBlur = (index) => {
    const line = lines[index];
    if (line.article && line.sellingPrice < line.article.purchasePrice) {
      alert('Selling price should not be lower than purchase price.');
      const updatedLines = [...lines];
      updatedLines[index].sellingPrice = line.article.defaultSellingPrice;
      setLines(updatedLines);
    }
  };



  const addLine = () => {
    const newLines = [...lines, { article: null, quantity: 1, sellingPrice: 0 }];
    updateLinesAndAmount(newLines);
  };

  const removeLine = (index) => {
    const newLines = [...lines];
    newLines.splice(index, 1);
    updateLinesAndAmount(newLines);
  };
  const setSaleDates = (saleData, currentDate) => {
    switch (saleData.type.toUpperCase()) {
      case 'ORDER':
        saleData.orderDate = currentDate;
        saleData.orderedBy = currentUser;
        break;
      case 'DELIVERY':
        saleData.deliveryDate = currentDate;
        saleData.deliverdBy = currentUser;
        break;
      case 'RETURN':
        saleData.returnDate = currentDate;
        saleData.returnedBy = currentUser;
        break;
      case 'CREDIT':
        saleData.creditDate = currentDate;
        saleData.creditedBy = currentUser;
        break;
      case 'INSTORE':
        saleData.inStoreSelledBy = currentUser;
        saleData.instoreSelledDate = currentDate;
        break;
      default:
        break; // Do nothing for other capitalizeFirstLettertypes
    }
  };
  const getCurrentDateTime = () => {
    const now = new Date();
    const offset = now.getTimezoneOffset() * 60000; // offset in milliseconds
    return (new Date(now - offset)).toISOString().slice(0, -1);
  };

  // Function to toggle packing input visibility
  const togglePackingInput = (index) => {
    const newLines = [...lines];
    const line = newLines[index];

    // Toggle the visibility of the packing input
    line.showPackingInput = !line.showPackingInput;

    // If the packing input is being hidden, set packing to null
    if (!line.showPackingInput) {
      line.packing = null;
    }

    setLines(newLines);
  };
  const handleSubmit = async (action) => {
    const currentDate = getCurrentDateTime(); // Get current date and time
    const saleData = {
      creationDate: currentDate,
      type: type.toUpperCase(),
      amount,
      discount,
      paidAmount,
      paymentMethod: paymentMethod.toUpperCase(),
      lineItems: lines.map(line => {
        // Assuming article has a property 'purchasePrice' for the cost price
        const purchasePrice = line.article ? line.article.purchasePrice : 0;
        const profitPerUnit = line.sellingPrice - purchasePrice;
        const totalProfit = profitPerUnit * (line.quantity * (line.packing || 1));

        return {
          article: line.article ? articles.find(a => a.id === line.article.id) : null,
          quantity: line.quantity,
          sellingPrice: line.sellingPrice,
          packing: line.packing,
          profit: totalProfit, // Add the profit here
        };
      }),
      customer: selectedCustomerId ? customers.find(c => c.id === selectedCustomerId) : null,
      createdBy: currentUser,
    };
    setSaleDates(saleData, currentDate);
    try {
      let savedSale;
      if (saleToEdit) {
        savedSale = await updateSale(saleToEdit.id, saleData);
      } else {
        console.log(saleData);
        savedSale = await createSale(saleData);
      }
      onSave(savedSale);
      if (action === 'downloadPdf') {
        const pdfBlob = generatePdfBlob(saleData, savedSale.id);
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, '_blank');
      } else if (action === 'downloadReceipt') {
        const receiptContent = generateReceiptBlob(saleData, savedSale.id);
        printReceipt(receiptContent);
      }
      handleMenuClose();
    } catch (error) {
      console.error('Error saving sale', error);
    }
  };
  const isMenuOpen = Boolean(anchorEl);

  // Function to handle menu opening
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to handle menu closing
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Function to handle 'Save Only'
  const handleSaveOnly = () => {
    handleSubmit('saveOnly');
  };

  const handleSaveAndDownload = () => {
    handleSubmit('downloadPdf');
  };

  const handleSaveAndDownloadReceipt = () => {
    handleSubmit('downloadReceipt');
  };
  const handleShowReceipt = () => {
    if (saleToEdit) {
      const receiptContent = generateReceiptBlob(saleToEdit, saleToEdit.id);
      printReceipt(receiptContent);
    }
  };
  const generatePdfBlob = (saleData, saleId) => {
    const doc = new jsPDF();

    // Titel hinzufügen
    doc.setFontSize(18);
    doc.text('Sale Invoice', 105, 15, null, null, 'center');
    const customerName = saleData.customer ? saleData.customer.name : 'No Customer';
    // Sale-Daten (Kopfzeile)
    doc.setFontSize(11);
    doc.text(`Date: ${saleData.creationDate}`, 10, 30);
    doc.text(`Customer: ${customerName}`, 10, 35);
    doc.text(`${saleData.type} N. ${saleId}`, 10, 40);
    doc.text(`Payment Method: ${saleData.paymentMethod}`, 10, 45);

    const columns = [
      { header: 'Item', dataKey: 'item' },
      { header: 'Packing', dataKey: 'packing' },
      { header: 'Quantity', dataKey: 'quantity' },
      { header: 'Price', dataKey: 'price' }
    ];

    const rows = saleData.lineItems.map(item => {
      const itemName = item.article ? item.article.name : 'Unknown Item';
      const itemPrice = item.sellingPrice ? `$${parseFloat(item.sellingPrice).toFixed(2)}` : 'N/A';
      const packingInfo = item.packing ? item.packing.toString() : '';

      return {
        item: itemName,
        packing: packingInfo,
        quantity: item.quantity.toString(),
        price: itemPrice
      };
    });

    // Add the table to the PDF
    doc.autoTable({
      columns: columns,
      body: rows,
      startY: 60
    });

    const activeIconStyle = {
      color: 'green', // This color will be used when the icon is active
    };


    // Container für die Beträge
    const finalY = doc.autoTable.previous.finalY + 10;
    doc.setFillColor(230, 230, 230); // Hellgrauer Hintergrund
    doc.rect(10, finalY, 190, 12, 'F'); // Gefülltes Rechteck

    // Fußzeile mit Gesamtbetrag und bezahltem Betrag
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0); // Schwarze Farbe für bessere Lesbarkeit
    const totalAmount = typeof saleData.amount === 'number' ? saleData.amount.toFixed(2) : 'N/A';

    const totalAmountStr = `Total Amount: $${totalAmount}`;

    // Umwandeln von paidAmount in einen numerischen Wert, falls notwendig
    const paidAmount = typeof saleData.paidAmount === 'number' ? saleData.paidAmount : parseFloat(saleData.paidAmount);

    // Überprüfen, ob paidAmount ein gültiger numerischer Wert ist, bevor toFixed angewendet wird
    const paidAmountStr = !isNaN(paidAmount) ? `Paid Amount: $${paidAmount.toFixed(2)}` : 'Paid Amount: N/A';


// Berechnung der X-Position für rechtsbündige Anordnung
    const totalAmountStrWidth = doc.getTextWidth(totalAmountStr);
    const paidAmountStrWidth = doc.getTextWidth(paidAmountStr);
    const rightMargin = 10;
    const totalAmountX = 200 - totalAmountStrWidth - rightMargin;
    const paidAmountX = 200 - paidAmountStrWidth - rightMargin;

// Textpositionierung
    doc.text(totalAmountStr, totalAmountX, finalY + 6);
    doc.text(paidAmountStr, paidAmountX, finalY + 12);

    // PDF speichern
    // const fileName = `Sale_${saleData.type}_${saleData.id}_${saleData.customer.name.replace(/\s+/g, '_')}.pdf`;
    // doc.save(fileName);
    return doc.output('blob');
  };
  const printReceipt = (receiptContent) => {
    const printWindow = window.open('', 'PRINT', 'height=600,width=800');
    printWindow.document.write(`
    <html>
      <head>
        <title>Print Receipt</title>
        <style>
          body {
            font-family: 'Arial', sans-serif;
            text-align: center;
            margin: 20px;
          }
          pre {
            text-align: left;
            margin: 20px auto;
            font-size: 16px;
          }
          button {
            margin: 10px;
            padding: 5px 15px;
            font-size: 16px;
            cursor: pointer;
          }
          @media print {
            button {
              display: none;
            }
          }
        </style>
      </head>
      <body>
        <pre>${receiptContent}</pre>
        <button onclick="window.print();">Print</button>
        <button onclick="window.close();">Close</button>
      </body>
    </html>`
    );
    printWindow.document.close();
    printWindow.focus();
  };

  const handleDateChange = (date) => {
    const newDate = new Date(date);
    const formattedDate = newDate.toISOString().split('.')[0];
    setCreationDate(formattedDate);
  };
  const generateReceiptBlob = (saleData, saleId) => {
    let receiptContent = '***** Sale Receipt *****\n\n';
    receiptContent += `Sale ID: ${saleId}\nDate: ${saleData.creationDate}\nCustomer: ${saleData.customer ? saleData.customer.name : 'No Customer'}\nType: ${saleData.type}\nPayment Method: ${saleData.paymentMethod}\n\n`;
    receiptContent += '----- Items -----\n';

    saleData.lineItems.forEach(item => {
      const packingInfo = item.packing ? `Packing: ${item.packing}, ` : '';
      const itemPrice = typeof item.sellingPrice === 'number' ? `$${item.sellingPrice.toFixed(2)}` : 'N/A';
      receiptContent += `${item.article ? item.article.name : 'Unknown Item'}\n${packingInfo}Qty: ${item.quantity}, Price: ${itemPrice}\n\n`;
    });

    receiptContent += '-----------------\n';
    const totalAmount = typeof saleData.amount === 'number' ? saleData.amount.toFixed(2) : '0.00';
    const discountAmount = typeof saleData.discount === 'number' ? saleData.discount.toFixed(2) : '0.00';
    const paidAmountStr = typeof saleData.paidAmount === 'number' ? saleData.paidAmount.toFixed(2) : '0.00';

    receiptContent += `Total Amount: $${totalAmount}\nDiscount: $${discountAmount}\nPaid Amount: $${paidAmountStr}\n`;
    receiptContent += '*****************\n';
    return receiptContent;
  };
  // Function to handle the printing of the receipt
  const handlePrintReceipt = () => {
    if (!window.epson || !window.epson.ePOSDevice) {
      console.error('ePOS SDK is not loaded');
      return;
    }

    // Ensure saleToEdit and its lineItems exist and are valid
    if (!saleToEdit || !Array.isArray(saleToEdit.lineItems) || saleToEdit.lineItems.length === 0) {
      console.error('Invalid or empty lineItems array');
      return;
    }

    // Generate receipt content from the current sale data
    const receiptContent = generateReceiptBlob(saleToEdit, saleToEdit.id);

    const epos = new window.epson.ePOSDevice();
    const address = '169.254.108.2';
    const port = '49152';

    epos.connect(address, port, (data) => {
      if (data === 'OK') {
        epos.createDevice('local_printer', epos.DEVICE_TYPE_PRINTER, {'crypto': false, 'buffer': false}, (devobj, retcode) => {
          if (retcode === 'OK') {
            const printData = devobj.createPrintData();
            printData.addText(receiptContent);
            printData.addCut(printData.CUT_FEED);
            devobj.sendData(printData, (response) => {
              if (response.success) {
                console.log('Receipt printed successfully');
              } else {
                console.error('Failed to print receipt');
              }
              epos.deleteDevice(devobj, () => {}, () => {});
            });
          } else {
            console.error('Failed to create device object');
          }
        });
      } else {
        console.error('Failed to connect to the printer');
      }
    });
  };


  const convertOrderToDelivery = () => {
    const currentDate = getCurrentDateTime();
    const updatedSaleData = {
      ...saleToEdit, // Spread the existing sale data
      deliveredBy: currentUser, // Set the current user as the deliveredBy
      deliveryDate: currentDate, // Set the current date and time as the deliveryDate
      type: 'DELIVERY',
      creationDate: saleToEdit.creationDate,
    };

    // Call a function to update the sale data in your backend
    // This could be similar to your existing updateSale function
    updateSale(saleToEdit.id, updatedSaleData)
    .then((updatedSale) => {
      onSave(updatedSale);
    })
    .catch((error) => {
      console.error('Error updating sale', error);
    });
  };
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const formStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    maxWidth: '90vw',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    maxHeight: '90vh',
  };
  if (viewingOnly) {
    return (
        <Box sx={{ p: 3, backgroundColor: 'background.paper', overflow: 'auto' }}>
          <IconButton
              aria-label="close"
              style={{ position: 'absolute', right: '8px', top: '8px', color: 'grey' }}
              onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Sale Details
          </Typography>
          <Grid container spacing={2}>
            {/* Displaying the type */}
            <Grid item xs={12}>
              <Typography variant="body1"><strong>Type:</strong> {type}</Typography>
            </Grid>

            {/* Displaying the selected customer */}
            <Grid item xs={12}>
              <Typography variant="body1"><strong>Customer:</strong> {selectedCustomerId ? customers.find(c => c.id === selectedCustomerId).name : 'No Customer'}</Typography>
            </Grid>

            {/* Displaying line items */}
            {lines.map((line, index) => (
                <Grid item xs={12} key={index}>
                  <Card variant="outlined" sx={{ mb: 2 }}>
                    <CardContent>
                      <Typography variant="body1"><strong>Article:</strong> {line.article ? line.article.name : 'No Article'}</Typography>
                      <Typography variant="body1"><strong>Selling Price:</strong> {line.sellingPrice}</Typography>
                      <Typography variant="body1"><strong>Quantity:</strong> {line.quantity}</Typography>
                      {line.showPackingInput && (
                          <Typography variant="body1"><strong>Packing:</strong> {line.packing}</Typography>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
            ))}

            {/* Displaying the amount */}
            <Grid item xs={12}>
              <Typography variant="body1"><strong>Amount:</strong> {amount}</Typography>
            </Grid>

            {/* Displaying the discount */}
            <Grid item xs={12}>
              <Typography variant="body1"><strong>Discount:</strong> {discount}</Typography>
            </Grid>

            {/* Displaying the payment method */}
            <Grid item xs={12}>
              <Typography variant="body1"><strong>Payment Method:</strong> {paymentMethod}</Typography>
            </Grid>

            {/* Displaying the paid amount */}
            { (paymentMethod === 'Cash' || paymentMethod === 'Check') && (
                <Grid item xs={12}>
                  <Typography variant="body1"><strong>Paid Amount:</strong> {paidAmount}</Typography>
                </Grid>
            )}
            <Grid container spacing={2} justifyContent="flex-end" sx={{mt: 2}}>
              <Button
                  variant="contained"
                  style={{backgroundColor: 'red', color: 'white'}} // Inline style for red close button
                  startIcon={<CloseIcon/>}
                  onClick={onClose}
              >
                Close
              </Button>
              <Button
                  variant="contained"
                  color="primary"
                  startIcon={<ReceiptIcon/>}
                  onClick={handleShowReceipt}
              >
                Show Thermal Receipt
              </Button>
              <button onClick={handlePrintReceipt}>Print Receipt</button>
            </Grid>
          </Grid>
        </Box>
    );
  }
  return (
      <Box sx={{ p: 3, backgroundColor: 'background.paper', overflow: 'auto' }}>
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            {saleToEdit ? 'Edit Sale' : 'Add New Sale'}
          </Typography>
          <form onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>

              {/* Type */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Type</InputLabel>
                  <Select
                      value={type}
                      label="Type"
                      onChange={(e) => setType(e.target.value)}
                      required
                  >
                    <MenuItem value="">Select Type</MenuItem>
                    <MenuItem value="Order">Order</MenuItem>
                    <MenuItem value="Instore">Instore</MenuItem>
                    <MenuItem value="Delivery">Delivery</MenuItem>
                    <MenuItem value="Return">Return</MenuItem>
                    <MenuItem value="Credit">Credit</MenuItem>
                  </Select>
                </FormControl>
              </Grid>


              {/* Customer Selection */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Customer</InputLabel>
                  <Select
                      value={selectedCustomerId}
                      label="Customer"
                      onChange={(e) => setSelectedCustomerId(e.target.value)}
                      required
                  >
                    <MenuItem value="">Select Customer</MenuItem>
                    {customers.map(customer => (
                        <MenuItem key={customer.id} value={customer.id}>
                          {customer.name}
                        </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Line Items */}
              {lines.map((line, index) => (
                  <Grid item xs={12} key={index}>
                    <Card variant="outlined" sx={{ mb: 2 }}>
                      <CardContent>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={12} sm={5}>
                            <FormControl fullWidth>
                              <InputLabel>Article</InputLabel>
                              <Select
                                  value={line.article ? line.article.id : ''}
                                  label="Article"
                                  onChange={(e) => handleLineChange(index, 'article', e.target.value)}
                                  required
                              >
                                <MenuItem value="">Select Article</MenuItem>
                                {articles.map(article => (
                                    <MenuItem key={article.id} value={article.id}>
                                      {article.name}
                                    </MenuItem>
                                ))}
                              </Select>

                            </FormControl>
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <TextField
                                label="Selling Price"
                                type="number"
                                value={line.sellingPrice || ''}
                                onChange={(e) => handleLineChange(index, 'sellingPrice', e.target.value)}
                                onBlur={() => handleSellingPriceBlur(index)}
                                required
                                fullWidth
                            />
                          </Grid>
                          <Grid item xs={4} sm={2}>
                            <TextField
                                label="Quantity"
                                type="number"
                                value={line.quantity|| ''}
                                onChange={(e) => handleLineChange(index, 'quantity', e.target.value)}
                                required
                                fullWidth
                            />
                          </Grid>
                          <Grid item xs={2} sm={1}>
                            <IconButton onClick={() => togglePackingInput(index)}>
                              <InventoryIcon style={line.showPackingInput ? { color: 'green' } : null} />
                            </IconButton>
                          </Grid>
                          {line.showPackingInput && (
                              <Grid item xs={4} sm={2}>
                                <TextField
                                    label="Packing"
                                    type="number"
                                    value={line.packing || ''}
                                    onChange={(e) => handleLineChange(index, 'packing', e.target.value)}
                                    fullWidth
                                />
                              </Grid>
                          )}
                          <Grid item xs={2} sm={2}>
                            <IconButton onClick={() => removeLine(index)}>
                              <RemoveCircleOutlineIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
              ))}
              <Grid item xs={12}>
                <Button startIcon={<AddCircleOutlineIcon />} onClick={addLine}>
                  Add Line
                </Button>
              </Grid>
              {/* Amount */}
              <Grid item xs={12}>
                <TextField
                    label="Amount"
                    type="number"
                    fullWidth
                    value={amount|| ''}
                    onChange={(e) => setAmount(e.target.value)}
                    required
                />
              </Grid>
              {/* Discount */}
              <Grid item xs={12}>
                <TextField
                    label="Discount"
                    type="number"
                    fullWidth
                    value={discount || ''}
                    onChange={(e) => setDiscount(Number(e.target.value) || 0)}
                    required
                />
              </Grid>

              {/* Payment Method */}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Payment Method</InputLabel>
                  <Select
                      value={paymentMethod}
                      label="Payment Method"
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      required
                  >
                    <MenuItem value="">Select Payment Method</MenuItem>
                    <MenuItem value="Cash">Cash</MenuItem>
                    <MenuItem value="Check">Check</MenuItem>
                    <MenuItem value="Credit">Credit</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {/* Paid Amount - Conditional Rendering */}
              { (paymentMethod === 'Cash' || paymentMethod === 'Check') && (
                  <Grid item xs={12}>
                    <TextField
                        label="Paid Amount"
                        type="number"
                        fullWidth
                        value={paidAmount|| ''}
                        onChange={(e) => setPaidAmount(e.target.value)}
                        required
                    />
                  </Grid>
              )}

              {/* Form Actions */}
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                {/* Save Options Button and Menu */}
                <Button
                    aria-controls="save-menu"
                    aria-haspopup="true"
                    onClick={handleMenuClick}
                    variant="contained"
                >
                  Save Options
                </Button>
                <Menu
                    id="save-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}  // Add this line
                >
                  <MenuItem onClick={handleSaveOnly}>Save Only</MenuItem>
                  <MenuItem onClick={handleSaveAndDownload}>Save and Download</MenuItem>
                  <MenuItem onClick={handleSaveAndDownloadReceipt}>Save and Download Receipt</MenuItem>
                </Menu>
              </Grid>
              {/* Convert Order to Delivery Button */}
              {saleToEdit && type === 'Order' && (
                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={convertOrderToDelivery}
                    >
                      Convert to Delivery
                    </Button>
                  </Grid>
              )}
            </Grid>
          </form>
        </Box>

);
};
export default SaleForm;