import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import UnitModal from './UnitModal';
import styles from '../../../igoodr.module.css';
import { getUnits, createUnit, updateUnit, deleteUnit } from '../../../services/unitService';

const UnitList = () => {
  const [units, setUnits] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [unitToEdit, setUnitToEdit] = useState(null);

  useEffect(() => {
    loadUnits();
  }, []);

  const loadUnits = async () => {
    try {
      const unitsData = await getUnits();
      setUnits(unitsData);
    } catch (error) {
      console.error('Error fetching units', error);
    }
  };

  const handleOpenModal = (unit = null) => {
    setUnitToEdit(unit);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveUnit = async (unitData) => {
    try {
      let updatedUnits;
      if (unitData.id) {
        await updateUnit(unitData.id, unitData);
        updatedUnits = units.map(unit => (unit.id === unitData.id ? unitData : unit));
      } else {
        const response = await createUnit(unitData);
        updatedUnits = [...units, response];
      }
      setUnits(updatedUnits);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error saving unit: ', error);
    }
  };

  const handleEditUnit = (unit) => {
    handleOpenModal(unit);
  };

  const handleDeleteUnit = async (unitId) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this unit?');
    if (isConfirmed) {
      try {
        await deleteUnit(unitId);
        setUnits(prevUnits => prevUnits.filter(unit => unit.id !== unitId));
      } catch (error) {
        console.error('Error deleting unit: ', error);
      }
    }
  };

  const handleUnitSaved = (savedUnit) => {
    setUnits(prevUnits => {
      const existingUnitIndex = prevUnits.findIndex(unit => unit.id === savedUnit.id);
      if (existingUnitIndex !== -1) {
        return prevUnits.map((unit, index) => (index === existingUnitIndex ? savedUnit : unit));
      } else {
        return [...prevUnits, savedUnit];
      }
    });
    setIsModalOpen(false);
  };

  return (
      <div className={styles.listContainer}>
        <div className={styles.tableHeader}>
          <button onClick={() => handleOpenModal()} className={`${styles.newButton} ${styles.buttonWithIcon}`}>
            <FontAwesomeIcon icon={faPlus} className={styles.faIcon} /> New Unit
          </button>
        </div>

        <table className={styles.Table}>
          <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          {units.map(unit => (
              <tr key={unit.id}>
                <td>{unit.name}</td>
                <td>{unit.description}</td>
                <td>
                  <button onClick={() => handleEditUnit(unit)} className={`${styles.editButton} ${styles.buttonWithIcon}`}>
                    <FontAwesomeIcon icon={faEdit} className={styles.faIcon} /> Edit
                  </button>
                  <button onClick={() => handleDeleteUnit(unit.id)} className={`${styles.deleteButton} ${styles.buttonWithIcon}`}>
                    <FontAwesomeIcon icon={faTrash} className={styles.faIcon} /> Delete
                  </button>
                </td>
              </tr>
          ))}
          </tbody>
        </table>

        {isModalOpen && (
            <UnitModal onClose={handleCloseModal} unitToEdit={unitToEdit} onSave={handleUnitSaved} />
        )}
      </div>
  );
};

export default UnitList;
