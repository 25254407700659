import React, { useState, useEffect } from 'react';
import styles from '../../igoodr.module.css'; // Adjust the path as needed

const UserForm = ({ isOpen, onClose, onSave, userData }) => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    if (isOpen && userData) {
      setUsername(userData.username || '');
      setEmail(userData.email || '');
      setRoles(userData.roles || []);
      // Do not set password here as it should not be retrieved or displayed
    } else {
      resetFormFields();
    }
  }, [isOpen, userData]);

  const resetFormFields = () => {
    setUsername('');
    setEmail('');
    setPassword('');
    setRoles([]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newUser = {
      id: userData.id,
      username,
      email,
      password, // Include password in the user data
      roles,
    };
    onSave(newUser);
  };

  const handleRoleChange = (event) => {
    const value = event.target.value;
    setRoles(value ? value.split(',') : []);
  };

  if (!isOpen) return null;

  return (
      <div className={styles.modalOverlay}>
        <div className={styles.modal}>
          <button onClick={onClose} className={styles.closeButton}>&times;</button>
          <h2 className={styles.formHeader}>{userData?.id ? 'Edit User' : 'Add New User'}</h2>
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.formRow}>
              <label className={styles.formLabel}>Username:</label>
              <input
                  type="text"
                  className={styles.formInput}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
              />
            </div>
            <div className={styles.formRow}>
              <label className={styles.formLabel}>Email:</label>
              <input
                  type="email"
                  className={styles.formInput}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
              />
            </div>
            <div className={styles.formRow}>
              <label className={styles.formLabel}>Password:</label>
              <input
                  type="password"
                  className={styles.formInput}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required={!userData.id} // Only require password when creating a new user
              />
            </div>
            <div className={styles.formRow}>
              <label className={styles.formLabel}>Roles:</label>
              <input
                  type="text"
                  className={styles.formInput}
                  value={roles.join(', ')}
                  onChange={handleRoleChange}
                  placeholder="Enter roles separated by commas"
              />
            </div>
            <div className={styles.modalActions}>
              <button type="button" onClick={onClose} className={styles.cancelButton}>
                Cancel
              </button>
              <button type="submit" className={styles.saveButton}>
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
  );
};

export default UserForm;
