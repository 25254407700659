// frontend/src/services/customerService.js

import axios from 'axios';
import { BASE_URL } from '../config/config';

const CUSTOMER_API = `${BASE_URL}/api/customers`;
const getAuthHeader = () => {
  const token = localStorage.getItem('token');
  console.log(token)
  if (!token) {
    return {};
  }
  return { Authorization: `Bearer ${token}` };
};
export const getAllCustomers = async () => {
  return await axios.get(`${CUSTOMER_API}`, { headers: getAuthHeader() });
};

export const getCustomerById = async (id) => {
  return await axios.get(`${CUSTOMER_API}/${id}`, { headers: getAuthHeader() });
};

export const createCustomer = async (customer) => {
  const response = await axios.post(`${CUSTOMER_API}`, customer, { headers: getAuthHeader() });
  return  response.data;
};

export const updateCustomer = async (id, customer) => {
  return await axios.put(`${CUSTOMER_API}/${id}`, customer, { headers: getAuthHeader() });
};

export const deleteCustomer = async (id) => {
  return await axios.delete(`${CUSTOMER_API}/${id}`, { headers: getAuthHeader() });
};
