import React, { useState, useEffect } from 'react';
import { getAllCustomers, deleteCustomer } from '../../services/customerService';
import CustomerForm from './CustomerForm';
import styles from '../../igoodr.module.css';

const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customerToEdit, setCustomerToEdit] = useState(null);

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    const response = await getAllCustomers();
    setCustomers(response.data);
  };

  const handleOpenModal = (customer = null) => {
    setCustomerToEdit(customer);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCustomerSaved = (savedCustomer) => {
    setCustomers(prevCustomers => {
      const existingCustomerIndex = prevCustomers.findIndex(c => c.id === savedCustomer.id);
      if (existingCustomerIndex !== -1) {
        // Update existing customer
        const updatedCustomers = [...prevCustomers];
        updatedCustomers[existingCustomerIndex] = savedCustomer;
        return updatedCustomers;
      } else {
        // Add new customer
        return [...prevCustomers, savedCustomer];
      }
    });
    setIsModalOpen(false);
    fetchCustomers();
  }

  const handleDelete = async (id) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this customer?");
    if (isConfirmed) {
      await deleteCustomer(id);
      fetchCustomers();
    }
  };

  return (
      <div className={styles.listContainer}>
        <div className={styles.tableHeader}>
          <h2>Customers</h2>
          <button onClick={() => handleOpenModal()} className={styles.newButton}>Create New Customer</button>
        </div>
        <table className={styles.Table}>
          <thead>
          <tr>
            <th>Name</th>
            <th>Contact Info</th>
            <th>Address</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          {customers.map(customer => (
              <tr key={customer.id}>
                <td>{customer.name}</td>
                <td>{customer.contactInfo}</td>
                <td>{customer.address}</td>
                <td>
                  <button
                      className={styles.editButton}
                      onClick={() => handleOpenModal(customer)}
                  >
                    Edit
                  </button>
                  <button
                      className={styles.deleteButton}
                      onClick={() => handleDelete(customer.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
          ))}
          </tbody>
        </table>
        {isModalOpen && (
            <CustomerForm
                onClose={handleCloseModal}
                customerToEdit={customerToEdit}
                onSave={handleCustomerSaved}
            />
        )}
      </div>
  );
};

export default CustomerList;