import axios from 'axios';
import { BASE_URL } from '../config/config';

const UNIT_API = `${BASE_URL}/units`;

const getAuthHeader = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    return {};
  }
  return { Authorization: `Bearer ${token}` };
};

export const getUnits = async () => {
  try {
    const response = await axios.get(UNIT_API, { headers: getAuthHeader() });
    return response.data;
  } catch (error) {
    console.error('Error fetching units:', error);
    throw error;
  }
};

export const getUnitById = async (id) => {
  try {
    const response = await axios.get(`${UNIT_API}/${id}`, { headers: getAuthHeader() });
    return response.data;
  } catch (error) {
    console.error('Error fetching unit:', error);
    throw error;
  }
};

export const createUnit = async (unitData) => {
  try {
    const response = await axios.post(UNIT_API, unitData, { headers: getAuthHeader() });
    return response.data;
  } catch (error) {
    console.error('Error creating unit:', error);
    throw error;
  }
};

export const updateUnit = async (unitId, unitData) => {
  try {
    const response = await axios.put(`${UNIT_API}/${unitId}`, unitData, { headers: getAuthHeader() });
    return response.data;
  } catch (error) {
    console.error('Error updating unit:', error);
    throw error;
  }
};

export const deleteUnit = async (unitId) => {
  try {
    await axios.delete(`${UNIT_API}/${unitId}`, { headers: getAuthHeader() });
  } catch (error) {
    console.error('Error deleting unit:', error);
    throw error;
  }
};
