import React, { useState } from 'react';
import styles from '../../../igoodr.module.css';
import { createUnit, updateUnit } from '../../../services/unitService';

const UnitModal = ({ onClose, unitToEdit, onSave }) => {
  const [name, setName] = useState(unitToEdit ? unitToEdit.name : '');
  const [description, setDescription] = useState(unitToEdit ? unitToEdit.description : '');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const unitData = { name, description };

    try {
      if (unitToEdit) {
        await updateUnit(unitToEdit.id, unitData);
      } else {
        const response = await createUnit(unitData);
        onSave(response);
      }
      onClose();
    } catch (error) {
      console.error('Error saving unit', error);
    }
  };

  return (
      <div className={styles.modalOverlay}>
        <div className={styles.modal}>
          <button onClick={onClose} className={styles.closeButton}>
            &times;
          </button>
          <h2 className={styles.formHeader}>{unitToEdit ? 'Edit Unit' : 'Add New Unit'}</h2>
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.formRow}>
              <label className={styles.formLabel}>Name:</label>
              <input
                  type="text"
                  className={styles.formInput}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
              />
            </div>
            <div className={styles.formRow}>
              <label className={styles.formLabel}>Description:</label>
              <input
                  type="text"
                  className={styles.formInput}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required
              />
            </div>
            <div className={styles.modalActions}>
              <button type="button" onClick={onClose} className={styles.cancelButton}>
                Cancel
              </button>
              <button type="submit" className={styles.saveButton}>
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
  );
};

export default UnitModal;
