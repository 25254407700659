import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import UserForm from './UserForm';
import { getUsers, createUser, updateUser, deleteUser } from '../../services/userService';
import styles from '../../igoodr.module.css'; // Adjust the path as needed

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userData, setUserData] = useState({
    id: null,
    username: '',
    email: '',
    roles: [],
  });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const fetchedUsers = await getUsers();
        setUsers(fetchedUsers);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleOpenModal = (user = null) => {
    setUserData(user ? { ...user } : {
      id: null,
      username: '',
      email: '',
      roles: [],
    });
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveUser = async (userData) => {
    try {
      let updatedUsers;
      if (userData.id) {
        await updateUser(userData.id, userData);
        updatedUsers = users.map(user => user.id === userData.id ? userData : user);
      } else {
        const newUser = await createUser(userData);
        updatedUsers = [...users, newUser];
      }
      setUsers(updatedUsers);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error saving user:', error);
    }
  };

  const handleEditUser = (user) => {
    handleOpenModal(user);
  };

  const handleDeleteUser = async (userId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this user?");
    if (isConfirmed) {
      try {
        await deleteUser(userId);
        setUsers(prevUsers => prevUsers.filter(user => user.id !== userId));
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    }
  };

  return (
      <div className={styles.listContainer}>
        <div className={styles.tableHeader}>
          <button onClick={() => handleOpenModal()} className={`${styles.newButton} ${styles.buttonWithIcon}`}>
            <FontAwesomeIcon icon={faPlus} className={styles.faIcon} /> New User
          </button>
        </div>

        <table className={styles.Table}>
          <thead>
          <tr>
            <th>Username</th>
            <th>Email</th>
            <th>Roles</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          {users.map((user) => (
              <tr key={user.id}>
                <td>{user.username}</td>
                <td>{user.email}</td>
                <td>{user.roles.join(', ')}</td>
                <td>
                  <button onClick={() => handleEditUser(user)} className={`${styles.editButton} ${styles.buttonWithIcon}`}>
                    <FontAwesomeIcon icon={faEdit} className={styles.faIcon} /> Edit
                  </button>
                  <button onClick={() => handleDeleteUser(user.id)} className={`${styles.deleteButton} ${styles.buttonWithIcon}`}>
                    <FontAwesomeIcon icon={faTrash} className={styles.faIcon} /> Delete
                  </button>
                </td>
              </tr>
          ))}
          </tbody>
        </table>

        <UserForm
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            onSave={handleSaveUser}
            userData={userData}
        />
      </div>
  );
};

export default UserList;
