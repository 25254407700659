// frontend/src/services/companyService.js

import axios from 'axios';
import { BASE_URL } from '../config/config';

const COMPANY_API = `${BASE_URL}/api/companies`;

const getAuthHeader = () => {
    const token = localStorage.getItem('token');
    console.log(token);
    if (!token) {
        return {};
    }
    return { Authorization: `Bearer ${token}` };
};

export const getAllCompanies = async () => {
    return await axios.get(COMPANY_API, { headers: getAuthHeader() });
};

export const getCompanyById = async (id) => {
    return await axios.get(`${COMPANY_API}/${id}`, { headers: getAuthHeader() });
};

export const createCompany = async (company) => {
    const response = await axios.post(COMPANY_API, company, { headers: getAuthHeader() });
    return response.data;
};

export const updateCompany = async (id, company) => {
    return await axios.put(`${COMPANY_API}/${id}`, company, { headers: getAuthHeader() });
};

export const deleteCompany = async (id) => {
    return await axios.delete(`${COMPANY_API}/${id}`, { headers: getAuthHeader() });
};
