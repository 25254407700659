import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash, faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import FamilyForm from './FamilyForm';
import styles from '../../igoodr.module.css';
import {
  getFamilies,
  deleteFamily,
  createFamily,
  updateFamily,
  createSubfamily, updateSubfamily, deleteSubfamily
} from '../../services/FamilyService';

const FamilyList = () => {
  const [families, setFamilies] = useState([]);
  const [expandedFamilies, setExpandedFamilies] = useState(new Set()); // Track expanded families
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [isSubfamily, setIsSubfamily] = useState(false);
  const [selectedParentId, setSelectedParentId] = useState(null);
  const [selectedParent, setSelectedParent] = useState(null);


  useEffect(() => {
    fetchFamilies();
  }, []);

  const fetchFamilies = async () => {
    try {
      const fetchedFamilies = await getFamilies();
      setFamilies(fetchedFamilies);
    } catch (error) {
      console.error('Error fetching families:', error);
    }
  };

  const toggleFamilyExpansion = (familyId) => {
    const newExpandedFamilies = new Set(expandedFamilies);
    if (newExpandedFamilies.has(familyId)) {
      newExpandedFamilies.delete(familyId);
    } else {
      newExpandedFamilies.add(familyId);
    }
    setExpandedFamilies(newExpandedFamilies);
  };

  const handleOpenModal = (item, isSubfamilyForm, parentId) => {
    const familyObject = isSubfamilyForm ? families.find(family => family.id === parentId) : null;
    setCurrentItem(item);
    setIsSubfamily(isSubfamilyForm);
    setSelectedParentId(parentId);
    setSelectedParent(familyObject); // Add a new state for selectedParent
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentItem(null);
    setIsSubfamily(false);
    setSelectedParentId(null);
  };
  const handleSave = async (familyData) => {
    try {
      if (familyData.id) {
        // Update existing family or subfamily
        const updateFunction = isSubfamily ? updateSubfamily : updateFamily;
        await updateFunction(familyData.id, familyData);
      } else {
        // Create new family or subfamily
        const createFunction = isSubfamily ? createSubfamily : createFamily;
        await createFunction(familyData);
      }
      await fetchFamilies(); // Fetch updated list of families
      handleCloseModal();
    } catch (error) {
      console.error('Error saving data: ', error);
    }
  };




  const handleDelete = async (id, isSubfamily) => {
    const confirmed = window.confirm('Are you sure you want to delete this item?');
    if (confirmed) {
      try {
        if (isSubfamily) {
          await deleteSubfamily(id); // Delete subfamily if isSubfamily is true
        } else {
          await deleteFamily(id); // Delete family otherwise
        }
        await fetchFamilies(); // Fetch the updated list of families
      } catch (error) {
        console.error('Error deleting item: ', error);
      }
    }
  };


  return (
      <div className={styles.listContainer}>
        <button onClick={() => handleOpenModal(null, false, null)} className={styles.newButton}>
          <FontAwesomeIcon icon={faPlus} /> Add New Family
        </button>
        <table className={styles.Table}>
          <thead>
          <tr>
            <th>Name</th>
            <th>Image</th>
            <th>Type</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          {families.map(family => (
              <React.Fragment key={family.id}>
                <tr>
                  <td onClick={() => toggleFamilyExpansion(family.id)}>
                    {expandedFamilies.has(family.id) ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronRight} />}
                    {family.name}
                  </td>
                  <td><img src={family.imageUrl} alt={family.name} style={{ width: '50px', height: '50px' }} /></td>
                  <td>Family</td>
                  <td>
                    <button onClick={() => handleOpenModal(family, false)}>
                      <FontAwesomeIcon icon={faEdit} /> Edit
                    </button>
                    <button onClick={() => handleDelete(family.id, false)}>
                      <FontAwesomeIcon icon={faTrash} /> Delete
                    </button>
                    <button onClick={() => handleOpenModal(null, true, family.id)}>
                      <FontAwesomeIcon icon={faPlus} /> Add Subfamily
                    </button>
                  </td>
                </tr>
                {expandedFamilies.has(family.id) && family.subfamilies?.map(subfamily => (
                    <tr key={subfamily.id} style={{ backgroundColor: '#f8f8f8' }}>
                      <td style={{ paddingLeft: '20px' }}>{subfamily.name}</td>
                      <td><img src={subfamily.imageUrl} alt={subfamily.name} style={{ width: '30px', height: '30px' }} /></td>
                      <td>Subfamily</td>
                      <td>
                        <button onClick={() => handleOpenModal(subfamily, true, family.id)}>
                          <FontAwesomeIcon icon={faEdit} /> Edit
                        </button>
                        <button onClick={() => handleDelete(subfamily.id, true)}>
                          <FontAwesomeIcon icon={faTrash} /> Delete
                        </button>
                      </td>
                    </tr>
                ))}
              </React.Fragment>
          ))}
          </tbody>
        </table>
        {isModalOpen && (
            <FamilyForm
                open={isModalOpen}
                onClose={handleCloseModal}
                onSave={handleSave}
                initialData={currentItem}
                isSubfamilyForm={isSubfamily}
                selectedParentId={selectedParentId}
                selectedParent={selectedParent} // Pass the selected parent family
            />
        )}
      </div>
  );
};

export default FamilyList;