import React, { useState, useEffect } from 'react';
import styles from '../../igoodr.module.css'; // Adjust the path as necessary
import { updateFamily, updateSubfamily } from "../../services/FamilyService"; // Adjust service import as necessary

const FamilyForm = ({ onSave, initialData, onClose, isSubfamilyForm, open, selectedParentId, selectedParent}) => {
  const [name, setName] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (open && initialData) {
      setName(initialData.name || '');
      setImageUrl(initialData.imageUrl || '');
    } else {
      resetFormFields();
    }
  }, [open, initialData]);

  const resetFormFields = () => {
    setName('');
    setImageUrl('');
  };

  const handleUpdateFamily = async (updatedFamilyData) => {
    try {
      const familyResponse = await (isSubfamilyForm ? updateSubfamily : updateFamily)(updatedFamilyData.id, updatedFamilyData);
      onSave(familyResponse);
      onClose();
    } catch (error) {
      console.error('Error updating family/subfamily: ', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newFamilyOrSubfamily = {
      name,
      imageUrl,
      ...(isSubfamilyForm ? { family: selectedParent } : {}) // Use the entire family object
    };
    console.log(newFamilyOrSubfamily);
    if (!initialData || !initialData.id) {
      onSave(newFamilyOrSubfamily); // Logic for creating a new family or subfamily
    } else {
      handleUpdateFamily({ id: initialData.id, ...newFamilyOrSubfamily }); // For updating an existing family or subfamily
    }
  };



  if (!open) return null;

  return (
      <div className={styles.modalOverlay}>
        <div className={styles.modal}>
          <button onClick={onClose} className={styles.closeButton}>&times;</button>
          <h2 className={styles.formHeader}>
            {initialData?.id ? (isSubfamilyForm ? 'Edit Subfamily' : 'Edit Family') : (isSubfamilyForm ? 'Add New Subfamily' : 'Add New Family')}
          </h2>
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.formRow}>
              <label className={styles.formLabel}>Name:</label>
              <input
                  type="text"
                  className={styles.formInput}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
              />
            </div>
            <div className={styles.formRow}>
              <label className={styles.formLabel}>Image URL:</label>
              <input
                  type="text"
                  className={styles.formInput}
                  value={imageUrl}
                  onChange={(e) => setImageUrl(e.target.value)}
              />
            </div>
            {/* Include other input fields as necessary */}
            <div className={styles.modalActions}>
              <button type="button" onClick={onClose} className={styles.cancelButton}>
                Cancel
              </button>
              <button type="submit" className={styles.saveButton}>
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
  );
};

export default FamilyForm;
