// src/i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importing translation files
import translationEN from './locales/en/translation.json';
import translationAR from './locales/ar/translation.json';

// the translations
const resources = {
    en: {
        translation: translationEN,
    },
    ar: {
        translation: translationAR,
    },
};
i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        debug: true, // Enable debug mode
        resources,
        lng: 'en', // language to use, you can change it to 'ar' to test Arabic
        fallbackLng: 'en', // use 'en' if detected lng is not available

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
