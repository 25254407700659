import React, { useState } from 'react';
import UnitList from './Unit/UnitList';
import styles from './../../igoodr.module.css';
import FamilyList from "../Family/FamilyList"; // Import the CSS module
import CompanyInfo from "./companyInformation/CompanyInfo";

const SettingsPage = () => {
  const [activeTab, setActiveTab] = useState('pricing');

  const getTabButtonClassName = (tabName) => {
    return `${styles.tabButton} ${activeTab === tabName ? styles.active : ''}`;
  };

  return (
      <div className={styles.tabContainer}>
        <h1 className={styles.tabHeader}>Settings</h1>
        <div className={styles.tabs}>
          <button
              className={getTabButtonClassName('units')}
              onClick={() => setActiveTab('units')}
          >
            Units
          </button>
          <button
              className={getTabButtonClassName('families')}
              onClick={() => setActiveTab('families')}
          >
            Families and Subfamilies
          </button>
            <button
              className={getTabButtonClassName('Company Information')}
              onClick={() => setActiveTab('Company Information')}
          >
              Company Information
          </button>
        </div>
        {activeTab === 'units' && <UnitList />}
        {activeTab === 'families' && <FamilyList />}
        {activeTab === 'Company Information' && < CompanyInfo/>}
      </div>
  );
};

export default SettingsPage;
