import axios from 'axios';
import { BASE_URL } from '../config/config';

const USER_API = `${BASE_URL}/api/users`;

// Helper function to get the authorization header
const getAuthHeader = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    return {};
  }
  return { Authorization: `Bearer ${token}` };
};

export const getUsers = async () => {
  try {
    const response = await axios.get(USER_API, { headers: getAuthHeader() });
    return response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

export const getUserById = async (id) => {
  try {
    const response = await axios.get(`${USER_API}/${id}`, { headers: getAuthHeader() });
    return response.data;
  } catch (error) {
    console.error('Error fetching user:', error);
    throw error;
  }
};

export const createUser = async (user) => {
  try {
    const response = await axios.post(USER_API, user, { headers: getAuthHeader() });
    return response.data;
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};

export const updateUser = async (id, user) => {
  try {
    const response = await axios.put(`${USER_API}/${id}`, user, { headers: getAuthHeader() });
    return response.data;
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
};

export const deleteUser = async (id) => {
  try {
    await axios.delete(`${USER_API}/${id}`, { headers: getAuthHeader() });
  } catch (error) {
    console.error('Error deleting user:', error);
    throw error;
  }
};
