import axios from 'axios';
import { BASE_URL } from '../config/config';

const PURCHASE_API = `${BASE_URL}/api/purchases`;
const ARTICLE_API = `${BASE_URL}/articles`;
const getAuthHeader = () => {
  const token = localStorage.getItem('token');
  console.log(token)
  if (!token) {
    return {};
  }
  return { Authorization: `Bearer ${token}` };
};
export const getArticles = async () => {
  try {
    const response = await axios.get(ARTICLE_API, { headers: getAuthHeader() });
    return response.data;
  } catch (error) {
    console.error('Error fetching articles:', error);
    throw error;
  }
};

export const getAllPurchases = async () => {
  const response = await axios.get(`${PURCHASE_API}`, { headers: getAuthHeader() });
  return response.data;
};

export const getPurchaseById = async (id) => {
  const response = await axios.get(`${PURCHASE_API}/${id}`, { headers: getAuthHeader() });
  return response.data;
};

export const createPurchase = async (purchase) => {
  const response = await axios.post(`${PURCHASE_API}`, purchase, { headers: getAuthHeader() });
  return response.data;
};

export const updatePurchase = async (id, purchase) => {
  const response = await axios.put(`${PURCHASE_API}/${id}`, purchase, { headers: getAuthHeader() });
  return response.data;
};

export const deletePurchase = async (id) => {
  await axios.delete(`${PURCHASE_API}/${id}`, { headers: getAuthHeader() });
};
