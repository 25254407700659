import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import HomePage from './components/Home/HomePage';
import ArticlesPage from './components/Article/ArticleList';
import SettingsPage from './components/Settings/SettingsPage';
import CustomerPage from './components/Customer/CustomerList';
import SupplierPage from './components/Supplier/SupplierList';
import SalePage from './components/Sale/SaleList';
import PurchasePage from './components/Purchase/PurchaseList';
import UserPage from './components/User/UserList';
import LoginPage from "./LoginPage";
import ProtectedRoute from "./ProtectedRoute"
import Navigation from './Navigation';
import './App.css';
import './i18n';
import {useTranslation} from "react-i18next";


const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
  const { i18n } = useTranslation();

  useEffect(() => {
    const handleStorageChange = () => {
      setIsLoggedIn(!!localStorage.getItem('token'));
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
  };
  useEffect(() => {
    const handleLanguageChange = () => {
      document.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
    };

    i18n.on('languageChanged', handleLanguageChange);

    // Cleanup the event listener when the component unmounts
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);
  return (
      <Router>
        {isLoggedIn && <Navigation isLoggedIn={isLoggedIn} onLogout={() => setIsLoggedIn(false)} />}
        <Routes>
          {isLoggedIn ? (
              <>
                <Route path="/" element={<HomePage />} />
                <Route path="/articles" element={<ProtectedRoute><ArticlesPage /></ProtectedRoute>} />
                <Route path="/settings" element={<ProtectedRoute><SettingsPage /></ProtectedRoute>} />
                <Route path="/customers" element={<ProtectedRoute><CustomerPage /></ProtectedRoute>} />
                <Route path="/suppliers" element={<ProtectedRoute><SupplierPage /></ProtectedRoute>} />
                <Route path="/sales" element={<ProtectedRoute><SalePage /></ProtectedRoute>} />
                <Route path="/purchases" element={<ProtectedRoute><PurchasePage /></ProtectedRoute>} />
                <Route path="/users" element={<ProtectedRoute><UserPage /></ProtectedRoute>} />
              </>
          ) : (
              <>
                <Route path="*" element={<Navigate replace to="/login" />} />
                <Route path="/login" element={<LoginPage onLoginSuccess={handleLoginSuccess} />} />
              </>
          )}
        </Routes>
      </Router>
  );
};

export default App;
