import React, { useState, useEffect } from 'react';
import { getAllSales, updateSale } from '../../services/saleService';
import {
  Box, Card, CardContent, Dialog, Grid, Typography, Button, Tabs, Tab, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip, TextField
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CancelIcon from '@mui/icons-material/Cancel';
import SaleForm from './SaleForm';

// Define the TabPanel component used to render tabs content.
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
        {value === index && (
            <Box sx={{ p: 3 }}>
              {children}
            </Box>
        )}
      </div>
  );
}

// Main SaleList component
const SaleList = () => {
  // State hooks for various component states
  const [sales, setSales] = useState([]);
  const [filteredSales, setFilteredSales] = useState({
    PENDING: [],
    CONFIRMED: [],
    FINISHED: [],
    IN_DELIVERY: [],
    DELIVERED: [],
  });
  const [viewingSale, setViewingSale] = useState(null);
  const [initialFetchDone, setInitialFetchDone] = useState(false);
  const [value, setValue] = useState(0);

  // Filter state for each column
  const [filters, setFilters] = useState({
    id: '',
    customer: '',
    amount: '',
    orderDate: '',
    status: '',
    paymentMethod: '',
  });

  // Event handler for changing tabs
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Fetch all sales once at the start and periodically
  useEffect(() => {
    const fetchSales = async () => {
      const response = await getAllSales();
      setSales(response);
      setInitialFetchDone(true);
    };
    fetchSales();
  }, []);

  useEffect(() => {
    if (!initialFetchDone) return;

    const intervalId = setInterval(async () => {
      const response = await getAllSales();
      if (response && Array.isArray(response)) {
        const newSales = response.filter(sale => !sales.some(existingSale => existingSale.id === sale.id));
        if (newSales.length > 0) {
          setSales(prev => [...newSales, ...prev]);
        }
      }
    }, 5000);
    return () => clearInterval(intervalId);
  }, [sales, initialFetchDone]);

  // Categorize and filter sales based on their status
  useEffect(() => {
    const categorizeAndFilterSales = () => {
      const statusCategories = {
        PENDING: [],
        CONFIRMED: [],
        FINISHED: [],
        IN_DELIVERY: [],
        DELIVERED: [],
      };

      sales.forEach((sale) => {
        if (statusCategories[sale.orderStatus]) {
          statusCategories[sale.orderStatus].push(sale);
        }
      });

      Object.keys(statusCategories).forEach(status => {
        statusCategories[status].sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate));
      });

      setFilteredSales(statusCategories);
    };

    if (sales.length > 0) {
      categorizeAndFilterSales();
    }
  }, [sales]);

  // Modal handlers
  const handleOpenModal = (sale) => {
    setViewingSale(sale);
  };

  const handleCloseModal = () => {
    setViewingSale(null);
  };

  // Update sale status
  const updateStatus = async (sale, newStatus, event) => {
    event.stopPropagation();
    const updatedData = { ...sale, orderStatus: newStatus };
    try {
      const updatedSale = await updateSale(sale.id, updatedData);
      setSales(sales.map(s => s.id === sale.id ? updatedSale : s));
    } catch (error) {
      console.error('Error updating sale status:', error);
    }
  };

  // Render buttons based on sale status
  const renderStatusButtons = (sale) => {
    if (sale.orderStatus === 'PENDING') {
      return (
          <>
            <Button color="primary" onClick={(e) => updateStatus(sale, 'CONFIRMED', e)}>Confirm</Button>
            <Button color="secondary" onClick={(e) => updateStatus(sale, 'CANCELED', e)}>Decline</Button>
          </>
      );
    } else if (sale.orderStatus === 'CONFIRMED') {
      return (
          <>
            <Button color="primary" onClick={(e) => updateStatus(sale, 'FINISHED', e)}>Finish</Button>
            <Button color="secondary" onClick={(e) => updateStatus(sale, 'CANCELED', e)}>Decline</Button>
          </>
      );
    }
    return null;
  };

  // Render status as a Chip component
  const getStatusChip = (status) => {
    switch (status) {
      case 'PENDING':
        return <Chip icon={<AccessTimeIcon />} label="Pending" color="default" />;
      case 'CONFIRMED':
        return <Chip icon={<AssignmentIcon />} label="Confirmed" color="primary" />;
      case 'FINISHED':
        return <Chip icon={<DoneAllIcon />} label="Finished" color="success" />;
      case 'IN_DELIVERY':
        return <Chip icon={<LocalShippingIcon />} label="In Delivery" color="info" />;
      case 'DELIVERED':
        return <Chip icon={<CheckCircleOutlineIcon />} label="Delivered" color="success" />;
      case 'CANCELED':
        return <Chip icon={<CancelIcon />} label="Canceled" color="error" />;
      default:
        return <Chip label={status} />;
    }
  };

  // Filter change handler
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  // Apply filters to sales data
  const filteredData = sales.filter(sale => {
    return Object.entries(filters).every(([key, value]) => {
      if (!value) return true; // If no filter, don't exclude
      if (key === 'id' || key === 'amount') {
        return sale[key].toString().includes(value);
      }
      return sale[key]?.toString().toLowerCase().includes(value.toLowerCase());
    });
  });

  return (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Kanban Board" />
            <Tab label="Detailed View" />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {/* Kanban Board View */}
          <Box sx={{ flexGrow: 1, overflow: 'auto', display: 'flex', padding: 3, gap: 2 }}>
            {Object.keys(filteredSales).map((status) => (
                <Grid key={status} container direction="column" spacing={2} sx={{ minWidth: 220, maxWidth: '20%', flex: '1 1 auto', overflowY: 'auto' }}>
                  <Grid item>
                    <Typography variant="h6" gutterBottom>{status}</Typography>
                  </Grid>
                  {filteredSales[status].map((sale) => (
                      <Grid item key={sale.id} onClick={() => handleOpenModal(sale)}>
                        <Card variant="outlined" sx={{ boxShadow: 1, cursor: 'pointer' }}>
                          <CardContent>
                            <Typography variant="subtitle1" gutterBottom>
                              Customer: {sale.customer ? sale.customer.name : 'Unknown'}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              Amount: ${sale.amount}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              Date: {new Date(sale.orderDate).toLocaleString()}
                            </Typography>
                            <div onClick={(e) => e.stopPropagation()}>
                              {renderStatusButtons(sale)}
                            </div>
                          </CardContent>
                        </Card>
                      </Grid>
                  ))}
                </Grid>
            ))}
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {/* Detailed View with Filters */}
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TextField
                        name="id"
                        onChange={handleFilterChange}
                        value={filters.id}
                        placeholder="Filter ID"
                        variant="standard"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                        name="customer"
                        onChange={handleFilterChange}
                        value={filters.customer}
                        placeholder="Filter Customer"
                        variant="standard"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                        name="amount"
                        onChange={handleFilterChange}
                        value={filters.amount}
                        placeholder="Filter Amount"
                        variant="standard"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                        name="orderDate"
                        onChange={handleFilterChange}
                        value={filters.orderDate}
                        placeholder="Filter Date"
                        variant="standard"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                        name="status"
                        onChange={handleFilterChange}
                        value={filters.status}
                        placeholder="Filter Status"
                        variant="standard"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                        name="paymentMethod"
                        onChange={handleFilterChange}
                        value={filters.paymentMethod}
                        placeholder="Filter Payment"
                        variant="standard"
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData.map((sale) => (
                    <TableRow
                        key={sale.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        onClick={() => handleOpenModal(sale)}
                        style={{ cursor: 'pointer' }}
                    >
                      <TableCell>{sale.id}</TableCell>
                      <TableCell>{sale.customer ? sale.customer.name : 'Unknown'}</TableCell>
                      <TableCell>${sale.amount}</TableCell>
                      <TableCell>{new Date(sale.orderDate).toLocaleString()}</TableCell>
                      <TableCell>{getStatusChip(sale.orderStatus)}</TableCell>
                      <TableCell>{sale.paymentMethod}</TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </TabPanel>
        <Dialog open={Boolean(viewingSale)} onClose={handleCloseModal} maxWidth="md" fullWidth>
          {viewingSale && <SaleForm saleToEdit={viewingSale} onSave={handleCloseModal} viewingOnly={true} onClose={handleCloseModal} />}
        </Dialog>
      </Box>
  );
};

export default SaleList;
