import React from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LanguageSwitcher from './LanguageSwitcher';
import './i18n';
import i18n from "i18next";

const Navigation = ({ isLoggedIn, onLogout }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const username = localStorage.getItem('username');

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    onLogout();
    navigate('/login');
  };

  if (!isLoggedIn) return null;
  console.log(i18n.language);
  return (
      <AppBar position="static">
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: 'flex' }}>
            <Link component={RouterLink} to="/" color="inherit" sx={{ marginRight: 2 }}>
              {t('navigation_home')}
            </Link>
            <Link component={RouterLink} to="/articles" color="inherit" sx={{ marginRight: 2 }}>
              {t('navigation_articles')}
            </Link>
            <Link component={RouterLink} to="/settings" color="inherit" sx={{ marginRight: 2 }}>
              {t('navigation_settings')}
            </Link>
            <Link component={RouterLink} to="/customers" color="inherit" sx={{ marginRight: 2 }}>
              {t('navigation_customers')}
            </Link>
            <Link component={RouterLink} to="/sales" color="inherit" sx={{ marginRight: 2 }}>
              {t('navigation_sales')}
            </Link>
            <Link component={RouterLink} to="/users" color="inherit" sx={{ marginRight: 2 }}>
              {t('navigation_users')}
            </Link>
          </Box>
          <LanguageSwitcher />
          <Typography variant="h6" component="div" sx={{ flexGrow: 0, marginRight: 2 }}>
            {username}
          </Typography>
          <Button color="inherit" onClick={handleLogout}>
            {t('navigation_logout')}
          </Button>
        </Toolbar>
      </AppBar>
  );
};

export default Navigation;
