import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from './config/config';
import { TextField, Button, Paper, Typography, Container, Box } from '@mui/material';

const LoginPage = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      console.log(`${BASE_URL}`);
      const response = await axios.post(`${BASE_URL}/authenticate`, { username, password });
      localStorage.setItem('token', response.data.jwt);
      localStorage.setItem('username', username);
      // Fetch and store user data
      await fetchUserData();
      console.log(response);
      console.log(response.data.user);
      onLoginSuccess();
      navigate('/');
    } catch (error) {
      console.error('Login failed:', error);
      setErrorMessage('Login failed. Please check your username and password.');
    }
  };
  const fetchUserData = async () => {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${BASE_URL}/api/users/me`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    localStorage.setItem('currentUser', JSON.stringify(response.data));
  };
  return (
      <Box
          sx={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            backgroundImage: 'url(/background.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: '100%'
          }}
      >
        <Container component="main" maxWidth="xs">
          <Paper elevation={6} sx={{ padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: 'rgba(255, 255, 255, 0.85)' }}>
            <Typography component="h1" variant="h5">
              Igoodr Login
            </Typography>
            <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
              <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  autoFocus
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
              />
              {errorMessage && (
                  <Typography color="error" align="center">
                    {errorMessage}
                  </Typography>
              )}
              <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
              >
                Login
              </Button>
            </Box>
          </Paper>
        </Container>
      </Box>
  );
};

export default LoginPage;
