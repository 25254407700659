import React, { useEffect, useState } from 'react';
import { createPurchase, updatePurchase, getArticles } from '../../services/purchaseService';
import { getAllSuppliers } from '../../services/supplierService';
import {
    Modal, Box, Typography, TextField, Select, MenuItem, FormControl, InputLabel,
    Button, IconButton, Grid, Card, CardContent
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const PurchaseForm = ({ onClose, purchaseToEdit, onSave, activeTab }) => {
    const today = new Date().toISOString().split('T')[0];
    const [creationDate, setCreationDate] = useState(today);
    const [type, setType] = useState('');
    const [amount, setAmount] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState('Cash');
    const [purchaseLines, setPurchaseLines] = useState([{ article: null, quantity: 1, purchasePrice: 0 }]);
    const [articles, setArticles] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [selectedSupplierId, setSelectedSupplierId] = useState('');
    const [paidAmount, setPaidAmount] = useState(0);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    useEffect(() => {
        const fetchArticlesAndSuppliers = async () => {
            try {
                const [fetchedArticles, fetchedSuppliers] = await Promise.all([getArticles(), getAllSuppliers()]);
                setArticles(fetchedArticles);
                setSuppliers(fetchedSuppliers);
                if (purchaseToEdit) {
                    setPurchaseToEditData(purchaseToEdit, fetchedArticles);
                }
            } catch (error) {
                console.error('Error fetching articles or suppliers', error);
            }
        };

        fetchArticlesAndSuppliers();
    }, [purchaseToEdit]);

    const setPurchaseToEditData = (purchase, fetchedArticles) => {
        setCreationDate(purchase.creationDate || '');
        setType(capitalizeFirstLetter(purchase.type) || '');
        setAmount(Number(purchase.amount) || 0);
        setPaymentMethod(capitalizeFirstLetter(purchase.paymentMethod) || 'Cash');
        setSelectedSupplierId(purchase.supplier?.id || '');
        setPaidAmount(purchase.paidAmount || 0);

        const editLines = purchase.purchaseLines.map(item => ({
            ...item,
            article: fetchedArticles.find(article => article.id === item.articleId) || null,
        }));
        setPurchaseLines(editLines.length > 0 ? editLines : [{ article: null, quantity: 1, purchasePrice: 0 }]);
    };
    useEffect(() => {
        if (activeTab !== 'All') {
            setType(convertTabToEnum(activeTab));
        }
    }, [activeTab]);
    const convertTabToEnum = (tabName) => {
        switch (tabName) {
            case 'ORDER':
                return 'Order';
            case 'RECEPTION':
                return 'Reception';
            case 'RETURN':
                return 'Return';
            // Handle other cases as needed
            default:
                return null;
        }
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        const purchaseData = {
            creationDate,
            type: type.toUpperCase(),
            amount,
            paymentMethod: paymentMethod.toUpperCase(),
            purchaseLines: purchaseLines.map(line => ({
                article: line.article ? articles.find(a => a.id ===line.article.id) : null,
                quantity: line.quantity,
                purchasePrice: line.purchasePrice
            })),
            supplier: selectedSupplierId ? suppliers.find(p => p.id === selectedSupplierId) : null,
            user: currentUser,
            paidAmount
        };

        try {
            let savedPurchase;
            if (purchaseToEdit) {
                savedPurchase = await updatePurchase(purchaseToEdit.id, purchaseData);
            } else {
                savedPurchase = await createPurchase(purchaseData);
            }
            onSave(savedPurchase);
            onClose();
        } catch (error) {
            console.error('Error saving purchase', error);
        }
    };

    const handleLineChange = (index, field, value) => {
        const newLines = [...purchaseLines];
        if (field === 'article') {
            const selectedArticleId = value !== '' ? value : null;
            const selectedArticle = articles.find(article => article.id === selectedArticleId);
            newLines[index].article = selectedArticle || null;
            newLines[index].purchasePrice = selectedArticle ? selectedArticle.purchasePrice : 0;
        } else {
            newLines[index][field] = value;
        }
        setPurchaseLines(newLines);
        updateAmount();
    };

    const addLine = () => {
        setPurchaseLines([...purchaseLines, { article: null, quantity: 1, purchasePrice: 0 }]);
    };

    const removeLine = (index) => {
        const newLines = [...purchaseLines];
        newLines.splice(index, 1);
        setPurchaseLines(newLines);
        updateAmount();
    };

    const updateAmount = () => {
        const total = purchaseLines.reduce((acc, line) => acc + (line.purchasePrice * line.quantity), 0);
        setAmount(total);
    };

    // Util functions
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    return (
        <Modal open onClose={onClose}>
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 'auto', maxWidth: '90vw', bgcolor: 'background.paper', boxShadow: 24, p: 4, overflow: 'auto', maxHeight: '90vh' }}>
                <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                    {purchaseToEdit ? 'Edit Purchase' : 'Add New Purchase'}
                </Typography>
                <form onSubmit={handleSubmit} noValidate>
                    {/* Date */}
                    <Grid item xs={12}>
                        <TextField
                            label="Date"
                            type="date"
                            fullWidth
                            value={creationDate}
                            onChange={(e) => setCreationDate(e.target.value)}
                            required
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>

                    {/* Type */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>Type</InputLabel>
                            <Select
                                value={type}
                                label="Type"
                                onChange={(e) => setType(e.target.value)}
                                required
                            >
                                <MenuItem value="Order">Order</MenuItem>
                                <MenuItem value="Reception">Reception</MenuItem>
                                <MenuItem value="Return">Return</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Supplier Selection */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>Supplier</InputLabel>
                            <Select
                                value={selectedSupplierId}
                                label="Supplier"
                                onChange={(e) => setSelectedSupplierId(e.target.value)}
                                required
                            >
                                {suppliers.map(supplier => (
                                    <MenuItem key={supplier.id} value={supplier.id}>
                                        {supplier.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Purchase Line Items */}
                    {purchaseLines.map((line, index) => (
                        <Grid item xs={12} key={index}>
                            <Card variant="outlined" sx={{ mb: 2 }}>
                                <CardContent>
                                    <Grid container spacing={2} alignItems="center">
                                        {/* Article Dropdown */}
                                        <Grid item xs={12} sm={5}>
                                            <FormControl fullWidth>
                                                <InputLabel>Article</InputLabel>
                                                <Select
                                                    value={line.article ? line.article.id : ''}
                                                    label="Article"
                                                    onChange={(e) => handleLineChange(index, 'article', e.target.value)}
                                                    required
                                                >
                                                    {articles.map(article => (
                                                        <MenuItem key={article.id} value={article.id}>
                                                            {article.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        {/* Buying Price */}
                                        <Grid item xs={6} sm={3}>
                                            <TextField
                                                label="Buying Price"
                                                type="number"
                                                value={line.purchasePrice || ''}
                                                onChange={(e) => handleLineChange(index, 'purchasePrice', e.target.value)}
                                                required
                                                fullWidth
                                            />
                                        </Grid>
                                        {/* Quantity */}
                                        <Grid item xs={4} sm={2}>
                                            <TextField
                                                label="Quantity"
                                                type="number"
                                                value={line.quantity || ''}
                                                onChange={(e) => handleLineChange(index, 'quantity', e.target.value)}
                                                required
                                                fullWidth
                                            />
                                        </Grid>
                                        {/* Remove Line Button */}
                                        <Grid item xs={2} sm={2}>
                                            <IconButton onClick={() => removeLine(index)}>
                                                <RemoveCircleOutlineIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        <Button startIcon={<AddCircleOutlineIcon />} onClick={addLine}>
                            Add Line
                        </Button>
                    </Grid>

                    {/* Amount */}
                    <Grid item xs={12}>
                        <TextField
                            label="Amount"
                            type="number"
                            fullWidth
                            value={amount || ''}
                            onChange={(e) => setAmount(e.target.value)}
                            required
                        />
                    </Grid>

                    {/* Payment Method */}
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>Payment Method</InputLabel>
                            <Select
                                value={paymentMethod}
                                label="Payment Method"
                                onChange={(e) => setPaymentMethod(e.target.value)}
                                required
                            >
                                <MenuItem value="Cash">Cash</MenuItem>
                            <MenuItem value="Check">Check</MenuItem>
                            <MenuItem value="Credit">Credit</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                {/* Paid Amount */}
                <Grid item xs={12}>
                    <TextField
                        label="Paid Amount"
                        type="number"
                        fullWidth
                        value={paidAmount || ''}
                        onChange={(e) => setPaidAmount(e.target.value)}
                        required
                    />
                </Grid>

                {/* Form Actions */}
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                    <Button onClick={onClose} variant="outlined">Cancel</Button>
                    <Button type="submit" variant="contained">Save Purchase</Button>
                </Grid>
          </form>
        </Box>
      </Modal>
);
};

export default PurchaseForm;
