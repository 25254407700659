import axios from 'axios';
import { BASE_URL } from '../config/config';

const ARTICLE_API = `${BASE_URL}/articles`;

// Helper function to get the authorization header
const getAuthHeader = () => {
  const token = localStorage.getItem('token');
  console.log(token)
  if (!token) {
    return {};
  }
  return { Authorization: `Bearer ${token}` };
};

export const getArticles = async () => {
  try {
    const response = await axios.get(ARTICLE_API, { headers: getAuthHeader() });
    return response.data;
  } catch (error) {
    console.error('Error fetching articles:', error);
    throw error;
  }
};

export const getArticleById = async (id) => {
  try {
    const response = await axios.get(`${ARTICLE_API}/${id}`, { headers: getAuthHeader() });
    return response.data;
  } catch (error) {
    console.error('Error fetching article:', error);
    throw error;
  }
};

export const createArticle = async (article) => {
  try {
    const response = await axios.post(ARTICLE_API, article, { headers: getAuthHeader() });
    return response.data;
  } catch (error) {
    console.error('Error creating article:', error);
    throw error;
  }
};

export const updateArticle = async (id, article) => {
  try {
    const response = await axios.put(`${ARTICLE_API}/${id}`, article, { headers: getAuthHeader() });
    return response.data;
  } catch (error) {
    console.error('Error updating article:', error);
    throw error;
  }
};

export const deleteArticle = async (id) => {
  try {
    await axios.delete(`${ARTICLE_API}/${id}`, { headers: getAuthHeader() });
  } catch (error) {
    console.error('Error deleting article:', error);
    throw error;
  }
};