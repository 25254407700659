import React, { useState, useEffect } from 'react';
import { getAllSuppliers, deleteSupplier } from '../../services/supplierService';
import SupplierForm from './SupplierForm';
import styles from '../../igoodr.module.css';

const SupplierList = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [supplierToEdit, setSupplierToEdit] = useState(null);

  useEffect(() => {
    fetchSuppliers();
  }, []);

  const fetchSuppliers = async () => {
    const response = await getAllSuppliers();
    setSuppliers(response.data);
  };

  const handleOpenModal = (supplier = null) => {
    setSupplierToEdit(supplier);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSupplierSaved = (savedSupplier) => {
    setSuppliers(prevSuppliers => {
      const existingSupplierIndex = prevSuppliers.findIndex(s => s.id === savedSupplier.id);
      if (existingSupplierIndex !== -1) {
        const updatedSuppliers = [...prevSuppliers];
        updatedSuppliers[existingSupplierIndex] = savedSupplier;
        return updatedSuppliers;
      } else {
        return [...prevSuppliers, savedSupplier];
      }
    });
    setIsModalOpen(false);
    fetchSuppliers();
  }

  const handleDelete = async (id) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this supplier?");
    if (isConfirmed) {
      await deleteSupplier(id);
      await fetchSuppliers();
    }
  };

  return (
      <div className={styles.listContainer}>
        <div className={styles.tableHeader}>
          <h2>Suppliers</h2>
          <button onClick={() => handleOpenModal()} className={styles.newButton}>Create New Supplier</button>
        </div>
        <table className={styles.Table}>
          <thead>
          <tr>
            <th>Name</th>
            <th>Contact Info</th>
            <th>Address</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          {suppliers.map(supplier => (
              <tr key={supplier.id}>
                <td>{supplier.name}</td>
                <td>{supplier.contactInfo}</td>
                <td>{supplier.address}</td>
                <td>
                  <button
                      className={styles.editButton}
                      onClick={() => handleOpenModal(supplier)}
                  >
                    Edit
                  </button>
                  <button
                      className={styles.deleteButton}
                      onClick={() => handleDelete(supplier.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
          ))}
          </tbody>
        </table>
        {isModalOpen && (
            <SupplierForm
                onClose={handleCloseModal}
                supplierToEdit={supplierToEdit}
                onSave={handleSupplierSaved}
            />
        )}
      </div>
  );
};

export default SupplierList;
