import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';
import CompanyModal from './CompanyModal';
import styles from '../../../igoodr.module.css';
import { getCompanyById, updateCompany, createCompany } from '../../../services/companyService';

const CompanyInfo = () => {
    const [company, setCompany] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        loadCompany();
    }, []);

    const loadCompany = async () => {
        try {
            // Assuming you always fetch the first company for now
            const response = await getCompanyById(1);
            if (response.data) {
                setCompany(response.data);
            }
        } catch (error) {
            console.error('Error fetching company data', error);
        }
    };

    const handleOpenModal = (company = null) => {
        setCompany(company);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleCompanySaved = (savedCompany) => {
        setCompany(savedCompany);
        setIsModalOpen(false);
    };

    return (
        <div className={styles.listContainer}>
            {company ? (
                <div className={styles.cardHeader}>
                    <button onClick={() => handleOpenModal(company)} className={`${styles.editButton} ${styles.buttonWithIcon}`}>
                        <FontAwesomeIcon icon={faEdit} className={styles.faIcon} /> Edit Company Info
                    </button>
                </div>
            ) : (
                <div className={styles.cardHeader}>
                    <button onClick={() => handleOpenModal()} className={`${styles.newButton} ${styles.buttonWithIcon}`}>
                        <FontAwesomeIcon icon={faPlus} className={styles.faIcon} /> Add New Company
                    </button>
                </div>
            )}

            {company ? (
                <div className={styles.card}>
                    <p><strong>Name:</strong> {company.name}</p>
                    <p><strong>Phone:</strong> {company.phone}</p>
                    <p><strong>Address:</strong> {company.address}</p>
                    <p><strong>Logo:</strong> <img src={company.logo} alt="Company Logo" style={{ maxWidth: '100px' }} /></p>
                </div>
            ) : (
                <p>Company information is not available. Please add new company information.</p>
            )}

            {isModalOpen && (
                <CompanyModal onClose={handleCloseModal} companyToEdit={company} onSave={handleCompanySaved} />
            )}
        </div>
    );
};

export default CompanyInfo;
