import React, { useEffect, useState } from 'react';
import styles from '../../igoodr.module.css';
import axios from "axios";
import { getUnits } from "../../services/unitService";
import { updateArticle } from "../../services/articleService";
import { getSubfamilies } from "../../services/FamilyService";

const ArticleModal = ({ isOpen, onClose, onSave, articleData }) => {
  const [name, setName] = useState('');
  const [barcode, setBarcode] = useState('');
  const [sellingPrice, setSellingPrice] = useState('');
  const [purchasePrice, setPurchasePrice] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [units, setUnits] = useState([]);
  const [subfamilies, setSubfamilies] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(articleData ? articleData.unit?.id : '');
  const [selectedSubfamilies, setSelectedSubfamilies] = useState(articleData ? articleData.subfamily?.id : '');
  const [minimumQuantity, setMinimumQuantity] = useState('');
  const [initialQuantity, setInitialQuantity] = useState('');
  const [quantity, setQuantity] = useState('');
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (isOpen && articleData) {
      setName(articleData.name || '');
      setBarcode(articleData.barcode || '');
      setSellingPrice(articleData.sellingPrice || '');
      setPurchasePrice(articleData.purchasePrice || '');
      setImageUrl(articleData.imageUrl || '');
      setMinimumQuantity(articleData.minimumQuantity || '');
      setVisible(articleData.visible !== undefined ? articleData.visible : true);

      if (articleData.id) {
        // When editing an existing article, set initialQuantity from articleData
        setInitialQuantity(articleData.initialQuantity || '');
        setQuantity(articleData.quantity || '');
      } else {
        // When creating a new article, set initialQuantity to the inputed value and quantity to the same value
        setInitialQuantity(articleData.initialQuantity || ''); // Set initialQuantity to the inputed value
        setQuantity(articleData.initialQuantity || ''); // Set quantity to the same value
      }

      setSelectedUnit(articleData.unit?.id || '');
      setSelectedSubfamilies(articleData.subfamily?.id || '');

    } else {
      resetFormFields();
    }
  }, [isOpen, articleData]);

  useEffect(() => {
    if (isOpen) {
      // Fetch units when the modal opens
      getUnits()
          .then(response => {
            setUnits(response);
          })
          .catch(error => console.error('Error fetching units', error));
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      // Fetch subfamilies when the modal opens
      getSubfamilies()
          .then(response => {
            setSubfamilies(response);
          })
          .catch(error => console.error('Error fetching subfamiles', error));
    }
  }, [isOpen]);

  const resetFormFields = () => {
    setName('');
    setBarcode('');
    setSellingPrice('');
    setPurchasePrice('');
    setImageUrl('');
    setMinimumQuantity('');
    setInitialQuantity('');
    setQuantity(initialQuantity); // Set quantity to initialQuantity when creating
    setSelectedUnit('');
    setSelectedSubfamilies('');
    setVisible(true);
  };

  const handleUpdateArticle = async (updatedArticleData) => {
    try {
      const articleResponse = await updateArticle(updatedArticleData.id, updatedArticleData);
      console.log(articleResponse);

      onSave(articleResponse);
      onClose();
    } catch (error) {
      console.error('Error updating article and pricings: ', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Ensure units are fetched before proceeding
    if (!units.length) {
      await getUnits()
          .then((response) => {
            setUnits(response);
          })
          .catch((error) => console.error('Error fetching units', error));
    }
    // Ensure subfamiles are fetched before proceeding
    if (!subfamilies.length) {
      await getSubfamilies()
          .then((response) => {
            setSubfamilies(response);
          })
          .catch((error) => console.error('Error fetching subfamilies', error));
    }
    const selectedUnitData = selectedUnit ? units.find((u) => u.id === parseInt(selectedUnit)) : null;
    const selectedSubfamilyData = selectedSubfamilies ? subfamilies.find((s) => s.id === parseInt(selectedSubfamilies)) : null;
    const newArticle = {
      id: articleData.id,
      name,
      barcode,
      sellingPrice: parseFloat(sellingPrice),
      purchasePrice: parseFloat(purchasePrice),
      imageUrl,
      minimumQuantity: parseInt(minimumQuantity),
      initialQuantity: parseInt(quantity),
      quantity: parseInt(quantity), // Use quantity when editing
      unit: selectedUnitData,
      subfamily: selectedSubfamilyData,
      visible,
    };

    if (articleData.id) {
      handleUpdateArticle(newArticle);
    } else {
      onSave(newArticle);
    }
  };

  if (!isOpen) return null;

  return (
      <div className={styles.modalOverlay}>
        <div className={styles.modal}>
          <button onClick={onClose} className={styles.closeButton}>&times;</button>
          <h2 className={styles.formHeader}>{articleData?.id ? 'Edit Article' : 'Add New Article'}</h2>
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.formRow}>
              {imageUrl && <img src={imageUrl} alt="Article" className={styles.articleImage} />}
            </div>
            <div className={styles.formRow}>
              <label className={styles.formLabel}>Name:</label>
              <input
                  type="text"
                  className={styles.formInput}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className={styles.formRow}>
              <label className={styles.formLabel}>Barcode:</label>
              <input
                  type="text"
                  className={styles.formInput}
                  value={barcode}
                  onChange={(e) => setBarcode(e.target.value)}
              />
            </div>
            <div className={styles.formRow}>
              <label className={styles.formLabel}>Selling Price:</label>
              <input
                  type="number"
                  className={styles.formInput}
                  value={sellingPrice}
                  onChange={(e) => setSellingPrice(e.target.value)}
              />
            </div>
            <div className={styles.formRow}>
              <label className={styles.formLabel}>Purchase Price (€):</label>
              <input
                  type="number"
                  className={styles.formInput}
                  value={purchasePrice}
                  onChange={(e) => setPurchasePrice(e.target.value)}
              />
            </div>
            <div className={styles.formRow}>
              <label className={styles.formLabel}>Image URL:</label>
              <input
                  type="text"
                  className={styles.formInput}
                  value={imageUrl}
                  onChange={(e) => setImageUrl(e.target.value)}
              />
            </div>
            <div className={styles.formRow}>
              <label className={styles.formLabel}>Unit:</label>
              <select
                  className={styles.formInput}
                  value={selectedUnit}
                  onChange={(e) => setSelectedUnit(e.target.value)}
              >
                <option value="">Select Unit</option>
                {units.map(unit => (
                    <option key={unit.id} value={unit.id}>{unit.name}</option>
                ))}
              </select>
            </div>
            <div className={styles.formRow}>
              <label className={styles.formLabel}>Subfamily:</label>
              <select
                  className={styles.formInput}
                  value={selectedSubfamilies}
                  onChange={(e) => setSelectedSubfamilies(e.target.value)}
              >
                <option value="">Select Subfamily</option>
                {subfamilies.map(subfamily => (
                    <option key={subfamily.id} value={subfamily.id}>{subfamily.name}</option>
                ))}
              </select>
            </div>
            <div className={styles.formRow}>
              <label className={styles.formLabel}>Minimum Quantity:</label>
              <input
                  type="number"
                  className={styles.formInput}
                  value={minimumQuantity}
                  onChange={(e) => setMinimumQuantity(e.target.value)}
              />
            </div>
            <div className={styles.formRow}>
              <label className={styles.formLabel}>
                {articleData?.id ? 'Quantity:' : 'Initial Quantity:'}
              </label>
              <input
                  type="number"
                  className={styles.formInput}
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
              />
            </div>
            <div className={styles.formRow}>
              <label className={styles.formLabel}>Visible:</label>
              <input
                  type="checkbox"
                  checked={visible}
                  onChange={(e) => setVisible(e.target.checked)}
              />
            </div>
            <div className={styles.modalActions}>
              <button type="button" onClick={onClose} className={styles.cancelButton}>
                Cancel
              </button>
              <button type="submit" className={styles.saveButton}>
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
  );
};

export default ArticleModal;
