import React, { useState } from 'react';
import { createSupplier, updateSupplier } from '../../services/supplierService';
import styles from '../../igoodr.module.css';

const SupplierForm = ({ onClose, supplierToEdit, onSave }) => {
  const [name, setName] = useState(supplierToEdit ? supplierToEdit.name : '');
  const [contactInfo, setContactInfo] = useState(supplierToEdit ? supplierToEdit.contactInfo : '');
  const [address, setAddress] = useState(supplierToEdit ? supplierToEdit.address : '');

  const handleSubmit = async (event) => {
    event.preventDefault();
    const supplierData = { name, contactInfo, address };

    try {
      let savedSupplier;
      if (supplierToEdit) {
        savedSupplier = await updateSupplier(supplierToEdit.id, supplierData);
      } else {
        savedSupplier = await createSupplier(supplierData);
      }
      onSave(savedSupplier);
      onClose();
    } catch (error) {
      console.error('Error saving supplier', error);
    }
  };

  return (
      <div className={styles.modalOverlay}>
        <div className={styles.modal}>
          <button onClick={onClose} className={styles.closeButton}>&times;</button>
          <h2 className={styles.formHeader}>{supplierToEdit ? 'Edit Supplier' : 'Add New Supplier'}</h2>
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.formRow}>
              <label className={styles.formLabel}>Name:</label>
              <input
                  type="text"
                  className={styles.formInput}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
              />
            </div>
            <div className={styles.formRow}>
              <label className={styles.formLabel}>Contact Info:</label>
              <input
                  type="text"
                  className={styles.formInput}
                  value={contactInfo}
                  onChange={(e) => setContactInfo(e.target.value)}
                  required
              />
            </div>
            <div className={styles.formRow}>
              <label className={styles.formLabel}>Address:</label>
              <input
                  type="text"
                  className={styles.formInput}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
              />
            </div>
            <div className={styles.modalActions}>
              <button type="button" onClick={onClose} className={styles.cancelButton}>
                Cancel
              </button>
              <button type="submit" className={styles.saveButton}>
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
  );
};

export default SupplierForm;
