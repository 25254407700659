import React from 'react';
import { useTranslation } from 'react-i18next';
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const { t } = useTranslation();


    const handleChange = (event) => {
        i18n.changeLanguage(event.target.value);
    };

    return (
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="language-select-label">Language</InputLabel>
            <Select
                labelId="language-select-label"
                id="language-select"
                value={i18n.language}
                onChange={handleChange}
                label= {t('Language')}
            >
                <MenuItem value="en">English</MenuItem>
                <MenuItem value="ar">العربية</MenuItem>
            </Select>
        </FormControl>
    );
};

export default LanguageSwitcher;
