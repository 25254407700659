import React, { useState, useEffect } from 'react';
import { getAllPurchases, deletePurchase } from '../../services/purchaseService';
import PurchaseForm from './PurchaseForm';
import {
    Button, Table, TableBody, TableCell, TableHead, TableRow, Paper, Tabs, Tab, IconButton
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const PurchaseList = () => {
    const [purchases, setPurchases] = useState([]);
    const [activeTab, setActiveTab] = useState('All');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [purchaseToEdit, setPurchaseToEdit] = useState(null);
    const [sortKey, setSortKey] = useState('creationDate');
    const [sortOrder, setSortOrder] = useState('asc');

    useEffect(() => {
        fetchPurchases();
    }, []);

    const fetchPurchases = async () => {
        try {
            const response = await getAllPurchases();
            console.log(response);
            setPurchases(Array.isArray(response) ? response : []);
        } catch (error) {
            console.error('Error fetching purchases:', error);
            setPurchases([]);
        }
    };

    const handleOpenModal = (purchase = null) => {
        setPurchaseToEdit(purchase);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handlePurchaseSaved = () => {
        setIsModalOpen(false);
        fetchPurchases();
    };

    const handleDelete = async (id) => {
        const isConfirmed = window.confirm("Are you sure you want to delete this purchase?");
        if (isConfirmed) {
            await deletePurchase(id);
            fetchPurchases();
        }
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleSortChange = (key) => {
        const order = sortKey === key && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortKey(key);
        setSortOrder(order);
    };

    const applySort = (data) => {
        return [...data].sort((a, b) => {
            if (a[sortKey] < b[sortKey]) return sortOrder === 'asc' ? -1 : 1;
            if (a[sortKey] > b[sortKey]) return sortOrder === 'asc' ? 1 : -1;
            return 0;
        });
    };

    const filteredAndSortedPurchases = applySort(purchases.filter(purchase =>
        activeTab === 'All' || (purchase.type && purchase.type.toUpperCase() === activeTab)
    ));


    const SortIcon = ({ columnName }) => {
        return sortKey === columnName && (
            sortOrder === 'asc' ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />
        );
    };

    const getDateToDisplay = (purchase) => {
        switch (purchase.type) {
            case 'ORDER':
                return purchase.orderDate || purchase.creationDate;
            case 'RECEPTION':
                return purchase.receptionDate || purchase.creationDate;
            case 'RETURN':
                return purchase.returnDate || purchase.creationDate;
            default:
                return purchase.creationDate;
        }
    };

    return (
        <Paper>
            <Tabs value={activeTab} onChange={handleTabChange} centered>
                <Tab label="All" value="All" />
                <Tab label="Order" value="ORDER" />
                <Tab label="Reception" value="RECEPTION" />
                <Tab label="Return" value="RETURN" />
            </Tabs>
            <div>
                <h2>Purchases</h2>
                <Button
                    variant="contained"
                    color="success"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() => handleOpenModal()}
                >
                    Create New Purchase
                </Button>
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell onClick={() => handleSortChange('creationDate')}>
                            Date {SortIcon({ columnName: 'creationDate' })}
                        </TableCell>
                        <TableCell onClick={() => handleSortChange('type')}>
                            Type {SortIcon({ columnName: 'type' })}
                        </TableCell>
                        <TableCell onClick={() => handleSortChange('amount')}>
                            Amount {SortIcon({ columnName: 'amount' })}
                        </TableCell>
                        <TableCell onClick={() => handleSortChange('paymentMethod')}>
                            Payment Method {SortIcon({ columnName: 'paymentMethod' })}
                        </TableCell>
                        <TableCell>User</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredAndSortedPurchases.map(purchase => (
                        <TableRow key={purchase.id}>
                            <TableCell>{getDateToDisplay(purchase)}</TableCell>
                            <TableCell>{purchase.type}</TableCell>
                            <TableCell>{purchase.amount}</TableCell>
                            <TableCell>{purchase.paymentMethod}</TableCell>
                            <TableCell>{purchase.user ? purchase.user.username : ''}</TableCell>
                            <TableCell>
                                <IconButton color="primary" onClick={() => handleOpenModal(purchase)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton color="error" onClick={() => handleDelete(purchase.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {isModalOpen && (
                <PurchaseForm
                    key={purchaseToEdit ? purchaseToEdit.id : 'new'}
                    onClose={handleCloseModal}
                    purchaseToEdit={purchaseToEdit}
                    onSave={handlePurchaseSaved}
                    activeTab={activeTab}
                />
            )}
        </Paper>
    );
};

export default PurchaseList;
