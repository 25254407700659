import axios from 'axios';
import { BASE_URL } from '../config/config';

const SUPPLIER_API = `${BASE_URL}/api/suppliers`;
const getAuthHeader = () => {
  const token = localStorage.getItem('token');
  console.log(token)
  if (!token) {
    return {};
  }
  return { Authorization: `Bearer ${token}` };
};
export const getAllSuppliers = async () => {
  return (await axios.get(`${SUPPLIER_API}`, {headers: getAuthHeader()})).data;
};

export const createSupplier = async (supplier) => {
  return await axios.post(`${SUPPLIER_API}`, supplier, { headers: getAuthHeader() });
};

export const updateSupplier = async (id, supplier) => {
  return await axios.put(`${SUPPLIER_API}/${id}`, supplier, { headers: getAuthHeader() });
};

export const deleteSupplier = async (id) => {
  return await axios.delete(`${SUPPLIER_API}/${id}`, { headers: getAuthHeader() });
};
