import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import ArticleModal from './ArticleModal';
import styles from '../../igoodr.module.css';
import { getArticles, createArticle, updateArticle, deleteArticle } from "../../services/articleService";

const ArticleList = () => {
  const [articles, setArticles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [articleData, setArticleData] = useState({
    id: null,
    name: '',
    barcode: '',
    sellingPrice: '',
    purchasePrice: '',
    imageUrl: '',
    subfamily: '',
  });

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const fetchedArticles = await getArticles();
        setArticles(fetchedArticles);
      } catch (error) {
        console.error('Error fetching articles: ', error);
      }
    };

    fetchArticles();
  }, []);

  const handleOpenModal = (article = null) => {
    if (article) {
      // If editing an existing article, pass its data
      setArticleData({ ...article });
    } else {
      // If creating a new article, pass default values
      setArticleData({
        id: null,
        name: '',
        barcode: '',
        sellingPrice: '',
        purchasePrice: '',
        imageUrl: '',
        subfamily: '',
      });
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSaveArticle = async (articleData) => {
    console.log(articleData);
    try {
      let updatedArticles;
      if (articleData.id) {
        await updateArticle(articleData.id, articleData);
        updatedArticles = articles.map((article) =>
            article.id === articleData.id ? articleData : article
        );
      } else {
        const newArticle = await createArticle(articleData);
        updatedArticles = [...articles, newArticle];
      }
      setArticles(updatedArticles); // Update the articles state
      setIsModalOpen(false);
      console.log(updatedArticles);
    } catch (error) {
      console.error('Error saving article: ', error);
    }
  };

  const handleEditArticle = (article) => {
    handleOpenModal(article);
  };

  const handleDeleteArticle = async (articleId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this article?");
    if (isConfirmed) {
      try {
        await deleteArticle(articleId);
        setArticles(prevArticles => prevArticles.filter(article => article.id !== articleId));
      } catch (error) {
        console.error('Error deleting article: ', error);
      }
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredArticles = articles.filter(article =>
      article.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      article.barcode.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalStockValue = filteredArticles.reduce((total, article) => {
    return total + (article.purchasePrice * (article.quantity || 0));
  }, 0);

  return (
      <div className={styles.listContainer}>
        <div className={styles.tableHeader}>
          <button onClick={() => handleOpenModal()} className={`${styles.newButton} ${styles.buttonWithIcon}`}>
            <FontAwesomeIcon icon={faPlus} className={styles.faIcon} /> New Article
          </button>
          <button className={styles.exportButton}>PDF Export</button>
        </div>
        <div className={styles.searchFilterArea}>
          <input
              type="text"
              placeholder="Search by Name or Barcode..."
              value={searchTerm}
              onChange={handleSearchChange}
              className={styles.searchInput}
          />
        </div>
        <div className={styles.totalStockValue}>
          <strong>Total Value of Stock: ${totalStockValue.toFixed(2)}</strong>
        </div>
        <table className={styles.Table}>
          <thead>
          <tr>
            <th>Picture</th>
            <th>Name</th>
            <th>Barcode</th>
            <th>Purchase Price</th>
            <th>Selling Price</th>
            <th>Unit</th>
            <th>Minimum Quantity</th>
            <th>Quantity</th>
            <th>Value of Stock</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          {filteredArticles.length > 0 ? (
              filteredArticles.map((article) => (
                  <tr key={article.id}>
                    <td><img src={article.imageUrl} alt={article.name} className={styles.listImage} /></td>
                    <td>{article.name}</td>
                    <td>{article.barcode}</td>
                    <td>${article.purchasePrice}</td>
                    <td>${article.sellingPrice}</td>
                    <td>{article.unit ? article.unit.name : ''}</td>
                    <td>{article.minimumQuantity !== null ? article.minimumQuantity : 'N/A'}</td>
                    <td>{article.quantity !== null ? article.quantity : 'N/A'}</td>
                    <td>${(article.purchasePrice * (article.quantity || 0)).toFixed(2)}</td> {/* Calculate value of stock */}
                    <td>
                      <button onClick={() => handleEditArticle(article)} className={`${styles.editButton} ${styles.buttonWithIcon}`}>
                        <FontAwesomeIcon icon={faEdit} className={styles.faIcon} /> Edit
                      </button>
                      <button onClick={() => handleDeleteArticle(article.id)} className={`${styles.deleteButton} ${styles.buttonWithIcon}`}>
                        <FontAwesomeIcon icon={faTrash} className={styles.faIcon} /> Delete
                      </button>
                    </td>
                  </tr>
              ))
          ) : (
              <tr>
                <td colSpan="8" style={{ textAlign: 'center' }}>No elements found</td>
              </tr>
          )}
          </tbody>
        </table>
        <ArticleModal
            isOpen={isModalOpen}
            onClose={handleCloseModal}
            onSave={handleSaveArticle}
            articleData={articleData}
        />
      </div>
  );
};

export default ArticleList;
