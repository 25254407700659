import React, { useEffect, useState } from 'react';
import { Card, Metric, Text, Flex, BadgeDelta, Grid, Select, SelectItem } from "@tremor/react";
import { getAllSales } from '../../services/saleService';

const HomePage = () => {
  const [dashboardData, setDashboardData] = useState([]);
  const [previousDashboardData, setPreviousDashboardData] = useState([]);
  const [selectedTimeframe, setSelectedTimeframe] = useState('today');
  const [bestSellingProducts, setBestSellingProducts] = useState([]);
  const [customerCreditsSum, setCustomerCreditsSum] = useState(0);

  useEffect(() => {
    fetchSalesData();
  }, [selectedTimeframe]);
  const getBestSellingProducts = (sales) => {
    const productSales = new Map();

    sales.forEach(sale => {
      sale.lineItems.forEach(item => {
        // Only process items with a valid article
        if (item.article) {
          const currentQuantity = productSales.get(item.article.name) || 0;
          productSales.set(item.article.name, currentQuantity + item.quantity);
        }
        // Items without a valid article are ignored
      });
    });

    const sortedProducts = Array.from(productSales).sort((a, b) => b[1] - a[1]);
    return sortedProducts.slice(0, 5);
  };

  const getCustomerCreditsSum = (sales) => {
    return sales
    .filter(sale => !sale.isPaid)
    .reduce((sum, sale) => sum + (sale.amount - sale.paidAmount), 0);
  };
  const fetchSalesData = async () => {
    try {
      const allSales = await getAllSales();
      const currentSales = filterSalesByTimeframe(allSales, selectedTimeframe);
      const previousSales = filterSalesByTimeframe(allSales, selectedTimeframe, true);

      setDashboardData(calculateDashboardData(currentSales));
      setPreviousDashboardData(calculateDashboardData(previousSales));
      setBestSellingProducts(getBestSellingProducts(allSales));
      setCustomerCreditsSum(getCustomerCreditsSum(allSales));
    } catch (error) {
      console.error('Error fetching sales data:', error);
    }
  };

  const calculateDashboardData = (sales) => {
    const inStoreSales = sales
    .filter(sale => sale.type === 'INSTORE')
    .reduce((acc, sale) => acc + sale.amount, 0);

    const onlineSales = sales
    .filter(sale => sale.type === 'ORDER')
    .reduce((acc, sale) => acc + sale.amount, 0);

    const profit = inStoreSales + onlineSales;

    return [
      { title: 'Sales In Store', metric: inStoreSales },
      { title: 'Sales Online', metric: onlineSales },
      { title: 'Profit', metric: profit }
    ];
  };

  const filterSalesByTimeframe = (sales, timeframe, isPrevious = false) => {
    const { startDate, endDate } = getDateRange(timeframe, isPrevious);
    console.log(startDate);
    return sales.filter(sale => {
      const saleDate = new Date(sale.creationDate);
      return saleDate >= startDate && saleDate <= endDate;
    });
  };

  const getDateRange = (timeframe, isPrevious) => {

    const now = new Date();
    const start = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const end = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    if (isPrevious) {
      switch (timeframe) {
        case 'today':
          start.setDate(start.getDate() - 1);
          end.setDate(end.getDate() - 1);
          break;
        case 'yesterday':
          start.setDate(start.getDate() - 2);
          end.setDate(end.getDate() - 2);
          break;
        case 'this_week':
          const firstDayLastWeek = start.getDate() - start.getDay() - 7;
          start.setDate(firstDayLastWeek);
          end.setDate(firstDayLastWeek + 6);
          break;
        case 'this_month':
          start.setMonth(start.getMonth() - 1, 1); // First day of the previous month
          end.setMonth(end.getMonth(), 0); // Last day of the previous month
          break;
        case 'last_month':
          start.setMonth(start.getMonth() - 2, 1); // First day of the month before the last
          end.setMonth(end.getMonth() - 1, 0); // Last day of the month before the last
          break;
        default:
          throw new Error('Invalid timeframe specified');
      }
    } else {
      switch (timeframe) {
        case 'today':
          // start and end are already set to today
          break;
        case 'yesterday':
          start.setDate(start.getDate() - 1);
          end.setDate(end.getDate() - 1);
          break;
        case 'this_week':
          const firstDayThisWeek = start.getDate() - start.getDay();
          start.setDate(firstDayThisWeek);
          end.setDate(firstDayThisWeek + 6);
          break;
        case 'this_month':
          start.setDate(1); // First day of this month
          end.setMonth(end.getMonth() + 1, 0); // Last day of this month
          break;
        case 'last_month':
          start.setMonth(start.getMonth() - 1, 1); // First day of the last month
          end.setMonth(end.getMonth(), 0); // Last day of the last month
          break;
        default:
          throw new Error('Invalid timeframe specified');
      }
    }

    start.setHours(0, 0, 0, 0); // Set to the start of the day
    end.setHours(23, 59, 59, 999); // Set to the end of the day

    return { startDate: start, endDate: end };
  };

// Function to calculate the max number of units sold for styling
  const maxUnitsSold = Math.max(...bestSellingProducts.map(product => product[1]), 0);

  const calculatePercentageChange = (current, previous) => {
    if (previous === 0) return 'N/A'; // Handle division by zero
    return ((current - previous) / previous * 100).toFixed(2) + '%';
  };
  const handleTimeframeChange = (value) => {
    setSelectedTimeframe(value);
  };

  return (
      <div>
        <br/>
        <div className="max-w-sm mx-auto space-y-6">
          <Select value={selectedTimeframe} onValueChange={handleTimeframeChange}>
            <SelectItem value="today">Today</SelectItem>
            <SelectItem value="yesterday">Yesterday</SelectItem>
            <SelectItem value="this_week">This Week</SelectItem>
            <SelectItem value="this_month">This Month</SelectItem>
            <SelectItem value="last_month">Last Month</SelectItem>
          </Select>
        </div>
        <br/>
        <Grid numItemsSm={2} numItemsLg={3} className="gap-6">
          {dashboardData.map((item, index) => {
            const prevData = previousDashboardData[index];
            const delta = prevData ? calculatePercentageChange(item.metric, prevData.metric) : 'N/A';
            return (
                <Card key={index}>
                  <Text>{item.title}</Text>
                  <Flex justifyContent="start" alignItems="baseline" className="truncate space-x-3">
                    <Metric>{`$ ${item.metric.toFixed(2)}`}</Metric>
                    {delta !== 'N/A' && <Text className="truncate">from {`$ ${prevData.metric.toFixed(2)}`}</Text>}
                  </Flex>
                  {delta !== 'N/A' && (
                      <Flex justifyContent="start" className="space-x-2 mt-4">
                        <BadgeDelta deltaType={delta.startsWith('-') ? 'decrease' : 'increase'} />
                        <Text>{delta}</Text>
                      </Flex>
                  )}
                </Card>
            );
          })}
        </Grid>
        <br/>
        {/* New Row for Best Selling Products and Customer Credits */}
        <Grid numItemsSm={2} numItemsLg={3} className="gap-6">
          {/* Render Best Selling Products */}
          <Card className="p-4 bg-white shadow-sm rounded-lg">
            <Text as="h3" className="font-bold text-lg mb-4">Best Selling Products</Text>
            <div>
              {bestSellingProducts.map((product, index) => {
                const barWidth = `${(product[1] / maxUnitsSold) * 100}%`; // Calculate width of bar based on units sold
                return (
                    <div key={index} className="mb-2">
                      <Text as="span" className="font-semibold">{product[0]}</Text>
                      <div style={{
                        height: '20px',
                        width: barWidth,
                        backgroundColor: 'blue', // Replace with your color of choice
                        color: 'white',
                        borderRadius: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: '10px',
                        margin: '5px 0'
                      }}>{product[1]} units</div>
                    </div>
                );
              })}
            </div>
          </Card>
          {/* Placeholder for Best Profitable Products */}
          <Card>
            <Text>Best Profitable Products (Placeholder)</Text>
            {/* Placeholder content */}
          </Card>
          {/* Customer Credits Sum */}
          <Card className="flex flex-col items-center justify-center p-4 bg-white shadow-sm rounded-lg">
            {/* Centrally aligned content */}
            <Text as="h3" className="font-bold text-lg text-center mb-2">Sum of Customer Credits</Text>
            <Metric className="text-3xl">{`$ ${customerCreditsSum.toFixed(2)}`}</Metric>
          </Card>
        </Grid>
      </div>
  );


};

export default HomePage;