import axios from 'axios';
import { BASE_URL } from '../config/config';

const FAMILY_API = `${BASE_URL}/families`;
const SUBFAMILY_API = `${BASE_URL}/subfamilies`;

const getAuthHeader = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    return {};
  }
  return { Authorization: `Bearer ${token}` };
};

export const getFamilies = async () => {
  try {
    const response = await axios.get(FAMILY_API, { headers: getAuthHeader() });
    return response.data;
  } catch (error) {
    console.error('Error fetching families:', error);
    throw error;
  }
};

export const createFamily = async (family) => {
  try {
    const response = await axios.post(FAMILY_API, family, { headers: getAuthHeader() });
    return response.data;
  } catch (error) {
    console.error('Error creating family:', error);
    throw error;
  }
};

export const updateFamily = async (id, family) => {
  try {
    const response = await axios.put(`${FAMILY_API}/${id}`, family, { headers: getAuthHeader() });
    return response.data;
  } catch (error) {
    console.error('Error updating family:', error);
    throw error;
  }
};

export const deleteFamily = async (id) => {
  try {
    await axios.delete(`${FAMILY_API}/${id}`, { headers: getAuthHeader() });
  } catch (error) {
    console.error('Error deleting family:', error);
    throw error;
  }
};

export const getSubfamilies = async (familyId) => {
  try {
    const response = await axios.get(`${SUBFAMILY_API}`, { headers: getAuthHeader() });
    return response.data;
  } catch (error) {
    console.error('Error fetching subfamilies:', error);
    throw error;
  }
};

export const createSubfamily = async (subfamily) => {
  try {
    const response = await axios.post(SUBFAMILY_API, subfamily, { headers: getAuthHeader() });
    return response.data;
  } catch (error) {
    console.error('Error creating subfamily:', error);
    throw error;
  }
};

export const updateSubfamily = async (id, subfamily) => {
  try {
    const response = await axios.put(`${SUBFAMILY_API}/${id}`, subfamily, { headers: getAuthHeader() });
    return response.data;
  } catch (error) {
    console.error('Error updating subfamily:', error);
    throw error;
  }
};

export const deleteSubfamily = async (id) => {
  try {
    await axios.delete(`${SUBFAMILY_API}/${id}`, { headers: getAuthHeader() });
  } catch (error) {
    console.error('Error deleting subfamily:', error);
    throw error;
  }
};
