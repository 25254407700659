import axios from 'axios';
import { BASE_URL } from '../config/config';

const SALE_API = `${BASE_URL}/api/sales`;
const ARTICLE_API = `${BASE_URL}/articles`;
const getAuthHeader = () => {
  const token = localStorage.getItem('token');
  console.log(token)
  if (!token) {
    return {};
  }
  return { Authorization: `Bearer ${token}` };
};
export const getArticles = async () => {
  try {
    const response = await axios.get(ARTICLE_API, { headers: getAuthHeader() });
    return response.data;
  } catch (error) {
    console.error('Error fetching articles:', error);
    throw error;
  }
};

export const getAllSales = async () => {
  const response = await axios.get(`${SALE_API}`, { headers: getAuthHeader() });
  return response.data;
};

export const getSaleById = async (id) => {
  const response = await axios.get(`${SALE_API}/${id}`, { headers: getAuthHeader() });
  return response.data;
};

export const createSale = async (sale) => {
  const response = await axios.post(`${SALE_API}`, sale, { headers: getAuthHeader() });
  return response.data;
};

export const updateSale = async (id, sale) => {
  const response = await axios.put(`${SALE_API}/${id}`, sale, { headers: getAuthHeader() });
  return response.data;
};

export const deleteSale = async (id) => {
  await axios.delete(`${SALE_API}/${id}`, { headers: getAuthHeader() });
};
