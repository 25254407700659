import React, { useState } from 'react';
import styles from '../../../igoodr.module.css';
import { updateCompany, createCompany } from '../../../services/companyService';

const CompanyModal = ({ onClose, companyToEdit, onSave }) => {
    const [name, setName] = useState(companyToEdit ? companyToEdit.name : '');
    const [phone, setPhone] = useState(companyToEdit ? companyToEdit.phone : '');
    const [address, setAddress] = useState(companyToEdit ? companyToEdit.address : '');
    const [logo, setLogo] = useState(companyToEdit ? companyToEdit.logo : '');

    const handleSubmit = async (event) => {
        event.preventDefault();
        const companyData = { name, phone, address, logo };

        try {
            let savedCompany;
            if (companyToEdit) {
                savedCompany = await updateCompany(companyToEdit.id, companyData);
            } else {
                savedCompany = await createCompany(companyData);
            }
            onSave(savedCompany.data);
            onClose();
        } catch (error) {
            console.error('Error saving company', error);
        }
    };

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modal}>
                <button onClick={onClose} className={styles.closeButton}>&times;</button>
                <h2 className={styles.formHeader}>{companyToEdit ? 'Edit Company' : 'Add New Company'}</h2>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <div className={styles.formRow}>
                        <label className={styles.formLabel}>Name:</label>
                        <input type="text" className={styles.formInput} value={name} onChange={(e) => setName(e.target.value)} required />
                    </div>
                    <div className={styles.formRow}>
                        <label className={styles.formLabel}>Phone:</label>
                        <input type="text" className={styles.formInput} value={phone} onChange={(e) => setPhone(e.target.value)} required />
                    </div>
                    <div className={styles.formRow}>
                        <label className={styles.formLabel}>Address:</label>
                        <input type="text" className={styles.formInput} value={address} onChange={(e) => setAddress(e.target.value)} required />
                    </div>
                    <div className={styles.formRow}>
                        <label className={styles.formLabel}>Logo:</label>
                        <input type="text" className={styles.formInput} value={logo} onChange={(e) => setLogo(e.target.value)} />
                    </div>
                    <div className={styles.modalActions}>
                        <button type="button" onClick={onClose} className={styles.cancelButton}>Cancel</button>
                        <button type="submit" className={styles.saveButton}>Save</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CompanyModal;
